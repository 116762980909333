<div class="relative w-full top-0 h-[4.6rem] bg-gray-900"></div>

<div [ngClass]="activeLang === 'AR' ? 'arabic' : 'english'">
  <div class="flex items-center justify-center my-10">
    <p
      class="text-center inline-block text-xl border border-black px-5 py-1 rounded-full"
    >
      {{ "generalManager" | transloco }}
    </p>
  </div>
  <div class="px-2 lg:px-14 grid lg:flex my-10">
    <div class="w-full sm:w-2/3 lg:w-1/3 lg:pr-5 center">
      <img
        class="rounded-lg"
        src="assets\aboutUS\amer.webp"
        width="100%"
        loading="lazy"
        alt=""
      />
    </div>
    <div class="w-full lg:w-2/3" [dir]="activeLang === 'AR' ? 'rtl' : 'ltr'">
      <div class="rounded-lg shadow-sm p-5 bg-white">
        <p class="text-base">
          {{ "manufacturingChips" | transloco }}
        </p>
      </div>
      <div class="rounded-lg shadow-sm p-5">
        <p class="text-base">
          {{ "rollingoutunique" | transloco }}
        </p>
      </div>
      <div class="rounded-lg shadow-sm p-5 bg-white">
        <p class="text-base">
          {{ "productionlines" | transloco }}
        </p>
      </div>
      <div class="rounded-lg shadow-sm p-5">
        <p class="text-base">
          {{ "Ourqualifiedteam" | transloco }}
        </p>
      </div>
      <div class="p-5 bg-white">
        <p class="font-extrabold text-xl mainColor">
          {{ "mrAmer" | transloco }}
        </p>
        <p class="font-medium text-lg mainColor">
          {{ "generalM" | transloco }}
        </p>
      </div>
    </div>
  </div>
  <div class="w-full my-5">
    <hr />
  </div>
  <div class="flex items-center justify-center my-20">
    <p
      class="text-center inline-block text-xl border border-black px-5 py-1 rounded-full"
    >
      {{ "certificates" | transloco }}
    </p>
  </div>
</div>

<div class="px-2 mt-10 mb-44">
  <img
    class="rounded-lg"
    src="assets/aboutUS/artboard.png"
    width="100%"
    loading="lazy"
  />
</div>
