import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, Meta, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { HreflangService } from 'app/services/hreflang.service';
import { BlogClass } from 'app/shared/blogs';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss']
})
export class BlogsComponent implements OnInit, OnDestroy {
  blogs$: Observable<BlogClass[]>;
  activeLang: string = 'EN';
  firstRun: boolean;
  private langChangesSubscription: Subscription;

  constructor(public router: ActivatedRoute, private _translocoService: TranslocoService, private meta: Meta, private sanitizer: DomSanitizer, private hreflangService: HreflangService,) { }

  ngOnInit(): void {
    if (typeof document === 'undefined') {
      return; // Not running in the browser, do nothing
    }
    this.blogs$ = this.router.data.pipe(map((data: any) => data.blogs));
    this.getActiveLang();
  }

  getActiveLang() {
    this.langChangesSubscription = this._translocoService.langChanges$.subscribe((activeLang) => {
      if (this.activeLang !== activeLang || !this.firstRun) {
        this.deleteSchemaMeta();
        setTimeout(() => {
          this.hreflangService.updateHreflangTags(activeLang, 'blogs');
        }, 1000);
        this.firstRun = true;
      }
      this.activeLang = activeLang;
    });
  }

  getSafeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  ngOnDestroy() {
    if (this.langChangesSubscription) {
      this.langChangesSubscription.unsubscribe();
    }
    // Delete Robots
    const robotTags = this.meta.getTags('name="robots"');
    if (robotTags.length > 0) {
      robotTags.forEach(tag => {
        try {
          this.meta.removeTagElement(tag);
        } catch (error) {
          console.error('Error removing robot tag:', error);
        }
      });
    }
    this.deleteSchemaMeta();
  }

  deleteSchemaMeta() {

    // Delete Hreflang
    const hreflangTags = this.meta.getTags('name="hreflang"');
    if (hreflangTags.length > 0) {
      hreflangTags.forEach(tag => {
        try {
          this.meta.removeTagElement(tag);
        } catch (error) {
          console.error('Error removing hreflang tag:', error);
        }
      });
    }


    // Delete Canonical
    try {
      this.meta.removeTag('rel=canonical');
    } catch (error) {
      console.error('Error removing canonical tag:', error);
    }
  }


}
