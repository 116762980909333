<div class="relative w-full top-0 h-[4.6rem] bg-gray-900"></div>
<div [ngClass]="activeLang === 'AR' ? 'arabic' : 'english'">
  <div *ngIf="item | async as item">
    <div class="flex items-center justify-center mt-10">
      <h1
        class="text-center arabic inline-block"
        [dir]="activeLang === 'AR' ? 'rtl' : 'ltr'"
      >
        {{ item.title[activeLang] }}
      </h1>
    </div>
    <div class="w-full my-5">
      <hr class="px-24" />
    </div>

    <div class="px-2 lg:px-20 xl:px-20">
      <div class="bg-[#dedede17] shadow-md rounded-lg grid items-center p-5">
        <div class="w-full lg:w-5/12 xl:w-5/12 center">
          <img
            *ngIf="item.image.url !== 'none'"
            [src]="item.image.url"
            loading="lazy"
            width="100%"
            alt="zirve pachaging"
          />
        </div>
        <div class="w-full mt-5" [dir]="activeLang === 'AR' ? 'rtl' : 'ltr'">
          <div [innerHTML]="blog.description[activeLang]"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="my-60"></div>
</div>
