<div class="relative w-full top-0 h-[4.6rem] bg-gray-900"></div>
<div *ngIf="product" [ngClass]="activeLang === 'AR' ? 'arabic' : 'english'">
  <div class="flex items-center justify-center mt-12 mb-4">
    <p
      class="text-center inline-block text-3xl border border-black px-5 py-1 rounded-full"
    >
      {{ product.title[activeLang] }}
    </p>
  </div>
  <div class="px-2 md:px-8 xl:px-24 grid md:flex mt-20">
    <div class="w-full px-2 md:px-0 md:w-5/12">
      <p class="font-medium" [dir]="activeLang === 'AR' ? 'rtl' : 'ltr'">
        {{ product.description[activeLang] }}
      </p>
    </div>
    <div class="w-full px-2 md:px-0 md:w-7/12 flex items-center justify-center">
      <img loading="lazy" [src]="product.image?.url" width="50%" alt="" />
    </div>
  </div>
  <div class="w-full py-20">
    <hr />
  </div>
  <app-send-offer-req
    [machineId]="id"
    [machineName]="machineName"
  ></app-send-offer-req>
</div>

<div class="my-60"></div>
