<div class="relative w-full top-0 h-[4.6rem] bg-gray-900"></div>
<div [ngClass]="activeLang === 'AR' ? 'arabic' : 'english'">
  <div class="flex items-center justify-center mt-5">
    <p
      class="text-center inline-block text-xl border border-black px-5 py-1 rounded-full"
    >
      {{ "suggestedBlogs" | transloco }}
    </p>
  </div>
  <div
    class="px-5 lg:px-10 xl:px-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 items-center gap-5 lg:gap-10 xl:gap-14 mt-10"
  >
    <div
      class="shadow-lg rounded-t-xl h-full"
      *ngFor="let item of blogs$ | async"
    >
      <a
        class="pt-5 hover:text-[#E60027] cursor-pointer"
        [routerLink]="['/', activeLang, 'blogs', item._id]"
        [dir]="activeLang === 'AR' ? 'rtl' : 'ltr'"
      >
        <div class="w-full h-64 mx-auto overflow-hidden">
          <img
            *ngIf="item.image.url !== 'none'"
            [src]="item.image.url"
            loading="lazy"
            alt="Image 1"
            class="center object-cover w-full h-full grayscale-0 hover:grayscale"
          />
        </div>
        <div class="p-2 mt-3">
          <p class="w-full font-bold text-center">
            {{ item.title[activeLang] }}
          </p>
        </div>
      </a>
    </div>
  </div>
</div>

<div class="my-60"></div>
