import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { NewsClass } from 'app/shared/news';
import { ThreeDViewerDialogComponent } from 'app/three-dviewer-dialog/three-dviewer-dialog';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-by-bags-products',
  templateUrl: './by-bags-products.component.html',
  styleUrls: ['./by-bags-products.component.scss']
})
export class ByBagsProductsComponent implements OnInit, OnDestroy {
  items$: Observable<NewsClass[]>;
  activeLang: string = 'EN';
  type: string = 'Search';
  type360: string = 'Search';
  private langChangesSubscription: Subscription;

  constructor(public router: ActivatedRoute, private _translocoService: TranslocoService,
    public dialog: MatDialog,) { }

  ngOnInit(): void {
    this.items$ = this.router.data.pipe(map((data: any) => data.items));
    this.getActiveLang();
    this.router.params.subscribe(params => {
      this.type360 = params['type'];
      this.type = this.getTitleTranslate(this.type360);

    });
  }

  getActiveLang() {
    this.langChangesSubscription = this._translocoService.langChanges$.subscribe((activeLang) => {
      this.activeLang = activeLang;
    });
  }

  ngOnDestroy() {
    if (this.langChangesSubscription) {
      this.langChangesSubscription.unsubscribe();
    }
  }

  getTitleTranslate(type): string {
    let title = '';
    let bagLang = {
      'AR': 'العبوات',
      'EN': 'BAGS',
      'TR': 'Paketler',
      'RU': 'Пакеты',
      'FR': 'Paquets'
    }
    let productLang = {
      'AR': 'المنتجات',
      'EN': 'Products',
      'TR': 'Ürünler',
      'RU': 'Продукты',
      'FR': 'Des produits'
    }
    if (type === 'bag') {
      title = bagLang[this.activeLang];
    } else {
      title = productLang[this.activeLang];
    }
    return title;
  }

  openViewerDialog(images, folder): void {
    const dialogRef = this.dialog.open(ThreeDViewerDialogComponent, {
      data: {
        images: images.map(el => el?.url),
        folderName: folder,
        routeName: 'bag_product',
        imgWidth: 'w-1/2',
      },
      width: '95rem',
      height: '42rem',
    });
    dialogRef.afterClosed().subscribe();
  }

}
