<div class="relative w-full top-0 h-[4.6rem] bg-gray-900"></div>
<div *ngIf="activeLang === 'TR'">
  <div class="container mx-auto px-4 py-8">
    <h1 class="text-2xl font-bold mb-4 text-center">
      İnternet Sitesi Çerez Politikası / Aydınlatma Metni
    </h1>
    <div class="content space-y-4">
      <h2 class="text-xl font-semibold">
        Zirve International Makine San. Tic. Ltd. Şti.
      </h2>
      <p>İNTERNET SİTESİ ÇEREZ POLİTİKASI / AYDINLATMA METNİ</p>
      <p>
        Zirve International Makine San. Tic. Ltd. Şti. (veri sorumlusu) olarak,
        kullanıcılarımızın internet (web) hizmetlerimizden güvenli ve eksiksiz
        şekilde yararlanmalarını sağlamak, kullanıcının uygulama ara yüzünde
        özelleştirebildiği tercihlerini depolamak, kullanıcı deneyimini
        geliştirmek, istatistik bilgilerini derlemek ve ziyaretçilerimizin
        gizliliğini korumak için çeşitli çerez veri dosyaları kullanıyoruz. Veri
        sorumlusu sıfatıyla, çerezler vasıtasıyla kişisel veriler
        toplanmaktadır.
      </p>
      <p>
        İnternet sayfamızı ziyaret ederek çerezlerin, bu İnternet Sitesi Çerez
        Politikası ile uyumlu bir şekilde çalışmasına onay vermiş olursunuz.
        Sitemizde çerezlerin devre dışı bırakılmasını istiyorsanız,
        tarayıcınızın ayarlarında düzenleme yapabilirsiniz. Bununla birlikte
        tarayıcı çerezlerinin kullanılmaması web sitemizdeki kullanıcı
        deneyiminizi etkileyebilir. Kalıcı veya oturum çerezlerinin
        kullanılmaması durumunda internet sitemizi bazı bileşenleri eksik olacak
        şekilde, sınırlı erişim olanaklarıyla görüntülemeye devam edebilirsiniz.
        İnternet sitemizden ve uygulamadan tam anlamıyla yararlanmak için
        çerezlere izin vermenizi öneririz.
      </p>
      <p>
        Veri sorumlusu, 6698 sayılı Kişisel Verileri Koruma Kanunu’nun (Kanun)
        5/2. maddesinde belirtilen şartları (hukuki sebepler) karşılamak
        şartıyla veya veri sahibinin açık rızası ile çerezler vasıtasıyla
        toplanan kişisel verileri işleyebilir. Veri sorumlusu olarak, web
        sitemizde ve uygulamalarımızda kullandığımız çerezleri yürürlükten
        kaldırabilir, türlerini veya işlevlerini değiştirebiliriz. Aydınlatma
        metnimiz üzerinde yapılan her türlü güncelleme web sitemizde ve web
        uygulamalarımızda yayınlanmasıyla birlikte yürürlük kazanacaktır.
      </p>
      <h3 class="text-lg font-semibold">
        Web sitelerimizde ve uygulamalarımızda ne tür çerezler kullanıyoruz?
      </h3>
      <ul class="list-disc pl-6">
        <li>
          <strong>Oturum Çerezleri:</strong> Üyelerimizin kullanıcı girişi
          aşamasında, şifrelerini sık sık yeniden girmelerini önlemek amacıyla
          kullanılmaktadır. Oturum çerezleri belirli bir süre için uygulama ve
          site için serbest dolaşım hakkı sağlarlar.
        </li>
        <li>
          <strong>Görsel Tercih Çerezleri:</strong> Üye davranış ve seçimlerine
          göre kişiselleştirilmiş içerik ve deneyim sunmak amacıyla
          kullanılırlar.
        </li>
        <li>
          <strong>İstatistik Çerezleri:</strong> Üyelerimizin ve konuklarımızın
          kullanım alışkanlıklarını analiz etmek ve uygulama istatistiklerini
          belirlemek için kullanılırlar.
        </li>
      </ul>
      <h3 class="text-lg font-semibold">
        Veri sahibi olarak sizin haklarınız nelerdir?
      </h3>
      <p>Kanun’a göre veri sahiplerinin,</p>
      <ul class="list-disc pl-6">
        <li>Kişisel veri işlenip işlenmediğini öğrenme,</li>
        <li>
          İşlendiği bildirilen kişisel verilerine ilişkin bilgi talep etme,
        </li>
        <li>
          İşlenme amacını ve verilerin amacına uygun kullanılıp kullanılmadığını
          öğrenme,
        </li>
        <li>
          Eksik veya yanlış işlenmiş verilerin düzeltilmesini isteme ve kişisel
          verilerin aktarıldığı kişiler var ise bu kapsamda yapılan işlemlerin
          de bu kişilere bildirilmesini isteme,
        </li>
        <li>
          Yurt içinde ve yurt dışında kişisel verilerin varsa aktarıldığı üçüncü
          kişileri öğrenme,
        </li>
        <li>
          Toplanan verilerin silinmesini veya yok edilmesini talep etme ve
          kişisel verilerin aktarıldığı kişiler var ise bu kapsamda yapılan
          işlemlerin de bu kişilere bildirilmesini isteme,
        </li>
        <li>
          Yasaya aykırı işlenmiş veriler dolayısıyla oluşabilecek zararların
          giderilmesini talep etme,
        </li>
        <li>
          İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz
          edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya
          çıkmasına itiraz etme hakları bulunmaktadır.
        </li>
      </ul>
      <p>
        Zirve International Makine San. Tic. Ltd. Şti. İNTERNET SİTESİ ÇEREZ
        POLİTİKASI / AYDINLATMA METNİ
      </p>
      <p>
        Bu haklarınıza ilişkin taleplerinizi, Kanun’un 11 inci maddesinde
        belirtilen haklar kapsamında yazılı olarak veya kayıtlı elektronik posta
        (KEP) adresi, güvenli elektronik imza, mobil imza ya da ilgili kişi
        tarafından veri sorumlusuna daha önce bildirilen ve veri sorumlusunun
        sisteminde kayıtlı bulunan elektronik posta adresini kullanmak suretiyle
        veya başvuru amacına yönelik geliştirilmiş bir yazılım ya da uygulama
        vasıtasıyla veri sorumlusuna iletebilirsiniz. Ayrıca, veri sorumlusunun
        www.zirvecompany.net adlı web sitesinde yer alan “Başvuru Formu” ile
        tarafımıza iletebilirsiniz. Başvurularınız en kısa sürede ve tebliğ
        tarihinden itibaren en geç 30 (otuz) gün içerisinde değerlendirilerek
        sonuçlandırılacaktır. Taleplere ilişkin olarak herhangi bir ücret talep
        edilmemesi esas olmakla birlikte veri sorumlusunun Kişisel Verileri
        Koruma Kurul’u tarafından belirlenen ücret tarifesi üzerinden ücret
        talep etme hakkı saklıdır.
      </p>
    </div>
  </div>
</div>
<div *ngIf="activeLang === 'EN'">
  <div class="container mx-auto px-4 py-8">
    <h1 class="text-3xl font-bold mb-4">Internet Site Cookie Policy</h1>
    <p class="mb-4">
      Zirve International Makine San. Tic. Ltd. Şti. (data controller), we use
      various cookie data files to ensure that our users can utilize our
      internet (web) services securely and completely, store preferences that
      can be customized in the user interface, enhance the user experience,
      compile statistical information, and protect the privacy of our visitors.
      Personal data is collected via cookies under the role of the data
      controller.
    </p>
    <p class="mb-4">
      By visiting our website, you consent to the operation of cookies in
      accordance with this Internet Site Cookie Policy. If you wish to disable
      cookies on our site, you can adjust your browser settings. However,
      disabling browser cookies may affect your user experience on our website.
      In the absence of persistent or session cookies, you may continue to view
      our website with some components missing and with limited access
      capabilities. We recommend allowing cookies to fully benefit from our
      website and application.
    </p>
    <p class="mb-4">
      The data controller may process personal data collected via cookies,
      provided that the conditions specified in Article 5/2 of the Personal Data
      Protection Law No. 6698 (Law) (legal reasons) are met or with the explicit
      consent of the data subject.
    </p>
    <p class="mb-4">
      As the data controller, we may withdraw, change the types or functions of
      cookies we use on our website and applications. Any updates made to our
      disclosure text will take effect upon publication on our website and web
      applications.
    </p>
    <h2 class="text-2xl font-bold mb-2">
      What types of cookies do we use on our websites and applications?
    </h2>
    <ul class="list-disc pl-5 mb-4">
      <li class="mb-2">
        <strong>Session Cookies:</strong> Used to prevent our members from
        frequently re-entering their passwords during user login. Session
        cookies provide free circulation rights for a certain period within the
        application and site.
      </li>
      <li class="mb-2">
        <strong>Visual Preference Cookies:</strong> Used to offer personalized
        content and experience based on member behavior and choices.
      </li>
      <li class="mb-2">
        <strong>Statistical Cookies:</strong> Used to analyze the usage habits
        of our members and guests and to determine application statistics.
      </li>
    </ul>
    <h2 class="text-2xl font-bold mb-2">
      What are your rights as a data subject?
    </h2>
    <p class="mb-4">According to the Law, data subjects have the right to:</p>
    <ul class="list-disc pl-5 mb-4">
      <li class="mb-2">Learn whether their personal data is processed,</li>
      <li class="mb-2">
        Request information regarding their processed personal data,
      </li>
      <li class="mb-2">
        Learn the purpose of data processing and whether their data is used in
        accordance with the intended purpose,
      </li>
      <li class="mb-2">
        Request the correction of incomplete or incorrectly processed data and
        request that the correction be communicated to third parties to whom the
        data has been transferred,
      </li>
      <li class="mb-2">
        Learn the third parties to whom personal data is transferred
        domestically or abroad,
      </li>
      <li class="mb-2">
        Request the deletion or destruction of the collected data and request
        that this action be communicated to third parties to whom the data has
        been transferred,
      </li>
      <li class="mb-2">
        Request compensation for damages arising from the unlawful processing of
        data,
      </li>
      <li class="mb-2">
        Object to the emergence of a result against the person himself/herself
        by analyzing the processed data exclusively through automated systems.
      </li>
    </ul>
    <p class="mb-4">
      Zirve International Makine San. Tic. Ltd. Şti. INTERNET SITE COOKIE POLICY
      / DISCLOSURE TEXT
    </p>
    <p class="mb-4">
      You can submit your requests regarding these rights in writing or by using
      a registered electronic mail (KEP) address, secure electronic signature,
      mobile signature, or via an electronic mail address previously notified to
      the data controller by the relevant person and registered in the data
      controller's system, or through software or an application developed for
      this purpose. You can also send your requests to us via the "Application
      Form" on the data controller’s website at
      <a href="https://www.zirvecompany.net" class="text-blue-500 underline"
        >www.zirvecompany.net</a
      >. Your applications will be evaluated and concluded as soon as possible,
      and no later than 30 (thirty) days from the date of notification. While it
      is essential not to charge any fees for requests, the data controller
      reserves the right to charge a fee based on the fee schedule set by the
      Personal Data Protection Board.
    </p>
  </div>
</div>
<div *ngIf="activeLang === 'AR'" dir="rtl">
  <div class="container mx-auto px-4 py-8">
    <h1 class="text-3xl font-bold mb-4">
      سياسة ملفات تعريف الارتباط الخاصة بالموقع الإلكتروني
    </h1>
    <p class="mb-4">
      نحن، شركة زيرف الدولية لصناعة الآلات والتجارة المحدودة (مراقب البيانات)،
      نستخدم ملفات تعريف الارتباط المختلفة لضمان أن يتمكن مستخدمونا من استخدام
      خدمات الإنترنت (الويب) لدينا بشكل آمن وكامل، وتخزين التفضيلات التي يمكن
      تخصيصها في واجهة المستخدم، وتحسين تجربة المستخدم، وجمع المعلومات
      الإحصائية، وحماية خصوصية زوارنا. يتم جمع البيانات الشخصية عبر ملفات تعريف
      الارتباط تحت دور مراقب البيانات.
    </p>
    <p class="mb-4">
      بزيارتك لموقعنا الإلكتروني، فإنك توافق على تشغيل ملفات تعريف الارتباط بما
      يتوافق مع سياسة ملفات تعريف الارتباط الخاصة بالموقع الإلكتروني هذه. إذا
      كنت ترغب في تعطيل ملفات تعريف الارتباط على موقعنا، يمكنك تعديل إعدادات
      المتصفح الخاص بك. ومع ذلك، قد يؤثر تعطيل ملفات تعريف الارتباط الخاصة
      بالمتصفح على تجربة المستخدم الخاصة بك على موقعنا الإلكتروني. في حالة عدم
      استخدام ملفات تعريف الارتباط الدائمة أو الجلسة، يمكنك الاستمرار في عرض
      موقعنا الإلكتروني مع بعض المكونات المفقودة ومع إمكانية الوصول المحدودة.
      نوصي بالسماح بملفات تعريف الارتباط للاستفادة الكاملة من موقعنا الإلكتروني
      والتطبيق.
    </p>
    <p class="mb-4">
      قد يعالج مراقب البيانات البيانات الشخصية التي يتم جمعها عبر ملفات تعريف
      الارتباط، بشرط أن يتم استيفاء الشروط المحددة في المادة 5/2 من قانون حماية
      البيانات الشخصية رقم 6698 (القانون) (الأسباب القانونية) أو بموافقة صريحة
      من موضوع البيانات.
    </p>
    <p class="mb-4">
      كمراقب للبيانات، قد نسحب، نغير أنواع أو وظائف ملفات تعريف الارتباط التي
      نستخدمها على موقعنا الإلكتروني وتطبيقاتنا. ستدخل أي تحديثات تتم على نص
      الإفصاح الخاص بنا حيز التنفيذ عند نشرها على موقعنا الإلكتروني وتطبيقاتنا
      الإلكترونية.
    </p>
    <h2 class="text-2xl font-bold mb-2">
      ما أنواع ملفات تعريف الارتباط التي نستخدمها على مواقعنا وتطبيقاتنا؟
    </h2>
    <ul class="list-disc pl-5 mb-4">
      <li class="mb-2">
        <strong>ملفات تعريف الارتباط للجلسة:</strong> تستخدم لمنع أعضائنا من
        إعادة إدخال كلمات المرور الخاصة بهم بشكل متكرر خلال تسجيل الدخول. توفر
        ملفات تعريف الارتباط للجلسة حقوق التجوال الحر لفترة محددة ضمن التطبيق
        والموقع.
      </li>
      <li class="mb-2">
        <strong>ملفات تعريف الارتباط لتفضيلات العرض:</strong> تستخدم لتقديم
        محتوى وتجربة مخصصة بناءً على سلوك وخيارات الأعضاء.
      </li>
      <li class="mb-2">
        <strong>ملفات تعريف الارتباط الإحصائية:</strong> تستخدم لتحليل عادات
        الاستخدام لأعضائنا وضيوفنا وتحديد إحصائيات التطبيق.
      </li>
    </ul>
    <h2 class="text-2xl font-bold mb-2">ما هي حقوقك كموضوع للبيانات؟</h2>
    <p class="mb-4">وفقًا للقانون، يحق لموضوعات البيانات:</p>
    <ul class="list-disc pl-5 mb-4">
      <li class="mb-2">معرفة ما إذا كانت بياناتهم الشخصية معالجة،</li>
      <li class="mb-2">طلب معلومات بخصوص بياناتهم الشخصية المعالجة،</li>
      <li class="mb-2">
        معرفة غرض معالجة البيانات وما إذا كانت بياناتهم مستخدمة وفقًا للغرض
        المقصود،
      </li>
      <li class="mb-2">
        طلب تصحيح البيانات غير المكتملة أو التي تمت معالجتها بشكل غير صحيح وطلب
        أن يتم إخطار الأطراف الثالثة التي تم نقل البيانات إليها بهذا التصحيح،
      </li>
      <li class="mb-2">
        معرفة الأطراف الثالثة التي تم نقل البيانات الشخصية إليها داخل البلاد أو
        خارجها،
      </li>
      <li class="mb-2">
        طلب حذف أو تدمير البيانات التي تم جمعها وطلب أن يتم إخطار الأطراف
        الثالثة التي تم نقل البيانات إليها بهذا الإجراء،
      </li>
      <li class="mb-2">
        طلب تعويض عن الأضرار الناتجة عن المعالجة غير القانونية للبيانات،
      </li>
      <li class="mb-2">
        الاعتراض على ظهور نتيجة ضد الشخص نفسه عن طريق تحليل البيانات المعالجة
        حصريًا من خلال أنظمة آلية.
      </li>
    </ul>
    <p class="mb-4">
      سياسة ملفات تعريف الارتباط الخاصة بالموقع الإلكتروني لشركة زيرف الدولية
      لصناعة الآلات والتجارة المحدودة
    </p>
    <p class="mb-4">
      يمكنك تقديم طلباتك المتعلقة بهذه الحقوق كتابةً أو باستخدام عنوان بريد
      إلكتروني مسجل (KEP)، توقيع إلكتروني آمن، توقيع متحرك، أو عبر عنوان بريد
      إلكتروني تم إبلاغه مسبقًا إلى مراقب البيانات من قبل الشخص المعني وتم
      تسجيله في نظام مراقب البيانات، أو من خلال برنامج أو تطبيق تم تطويره لهذا
      الغرض. يمكنك أيضًا إرسال طلباتك إلينا عبر "نموذج الطلب" على موقع مراقب
      البيانات على
      <a href="https://www.zirvecompany.net" class="text-blue-500 underline"
        >www.zirvecompany.net</a
      >. سيتم تقييم طلباتك وحسمها في أقرب وقت ممكن، وفي موعد لا يتجاوز 30
      (ثلاثين) يومًا من تاريخ الإخطار. بينما من الأساسي عدم فرض أي رسوم على
      الطلبات، يحتفظ مراقب البيانات بحق فرض رسوم بناءً على جدول الرسوم الذي
      تحدده هيئة حماية البيانات الشخصية.
    </p>
    <p class="mb-4">تم ترجمة هذا النص من التركية.</p>
  </div>
</div>
<div *ngIf="activeLang === 'FR'">
  <div class="container mx-auto px-4 py-8">
    <h1 class="text-3xl font-bold mb-5">
      Politique de Cookies du Site Internet
    </h1>
    <h2 class="text-xl font-semibold mb-4">
      Zirve International Makine San. Tic. Ltd. Şti.
    </h2>

    <section class="mb-6">
      <p class="mb-4">
        En tant que Zirve International Makine San. Tic. Ltd. Şti. (responsable
        des données), nous utilisons divers fichiers de données de cookies pour
        garantir que nos utilisateurs puissent utiliser nos services Internet
        (web) en toute sécurité et de manière complète, stocker les préférences
        personnalisables dans l'interface utilisateur, améliorer l'expérience
        utilisateur, compiler des informations statistiques et protéger la vie
        privée de nos visiteurs. Les données personnelles sont collectées via
        des cookies sous le rôle du responsable des données.
      </p>
      <p class="mb-4">
        En visitant notre site web, vous consentez au fonctionnement des cookies
        conformément à cette politique de cookies du site Internet. Si vous
        souhaitez désactiver les cookies sur notre site, vous pouvez ajuster les
        paramètres de votre navigateur. Cependant, désactiver les cookies de
        votre navigateur peut affecter votre expérience utilisateur sur notre
        site web. En l'absence de cookies persistants ou de session, vous pouvez
        continuer à afficher notre site web avec certains composants manquants
        et avec des capacités d'accès limitées. Nous vous recommandons de
        permettre les cookies pour bénéficier pleinement de notre site web et de
        notre application.
      </p>
      <p class="mb-4">
        Le responsable des données peut traiter les données personnelles
        collectées via des cookies, à condition que les conditions spécifiées
        dans l'article 5/2 de la Loi sur la protection des données personnelles
        n° 6698 (Loi) (raisons juridiques) soient remplies ou avec le
        consentement explicite de la personne concernée.
      </p>
      <p class="mb-4">
        En tant que responsable des données, nous pouvons retirer, changer les
        types ou les fonctions des cookies que nous utilisons sur notre site web
        et nos applications. Toute mise à jour apportée à notre texte de
        divulgation prendra effet lors de sa publication sur notre site web et
        nos applications web.
      </p>
    </section>

    <section class="mb-6">
      <h3 class="text-lg font-semibold mb-3">
        Quels types de cookies utilisons-nous sur nos sites web et applications?
      </h3>
      <ul class="list-disc pl-5">
        <li class="mb-2">
          <strong>Cookies de session:</strong> Utilisés pour empêcher nos
          membres de saisir fréquemment leurs mots de passe lors de la connexion
          utilisateur. Les cookies de session fournissent des droits de
          circulation libre pour une certaine période au sein de l'application
          et du site.
        </li>
        <li class="mb-2">
          <strong>Cookies de préférence visuelle:</strong> Utilisés pour offrir
          un contenu et une expérience personnalisés en fonction du comportement
          et des choix des membres.
        </li>
        <li class="mb-2">
          <strong>Cookies statistiques:</strong> Utilisés pour analyser les
          habitudes d'utilisation de nos membres et invités et déterminer les
          statistiques de l'application.
        </li>
      </ul>
    </section>

    <section>
      <h3 class="text-lg font-semibold mb-3">
        Quels sont vos droits en tant que personne concernée?
      </h3>
      <p class="mb-4">
        Selon la Loi, les personnes concernées ont le droit de:
      </p>
      <ul class="list-disc pl-5 mb-4">
        <li class="mb-2">
          Apprendre si leurs données personnelles sont traitées,
        </li>
        <li class="mb-2">
          Demander des informations concernant leurs données personnelles
          traitées,
        </li>
        <li class="mb-2">
          Apprendre l'objectif du traitement des données et si leurs données
          sont utilisées conformément à l'objectif prévu,
        </li>
        <li class="mb-2">
          Demander la correction des données incomplètes ou incorrectement
          traitées et demander que la correction soit communiquée aux tiers
          auxquels les données ont été transférées,
        </li>
        <li class="mb-2">
          Apprendre les tiers auxquels les données personnelles sont transférées
          au pays ou à l'étranger,
        </li>
        <li class="mb-2">
          Demander la suppression ou la destruction des données collectées et
          demander que cette action soit communiquée aux tiers auxquels les
          données ont été transférées,
        </li>
        <li class="mb-2">
          Demander une indemnisation pour les dommages résultant du traitement
          illégal des données,
        </li>
        <li class="mb-2">
          S'opposer à l'apparition d'un résultat contre la personne elle-même en
          analysant les données traitées exclusivement par des systèmes
          automatisés.
        </li>
      </ul>
    </section>

    <p class="mt-6 text-sm text-gray-600">
      Vous pouvez soumettre vos demandes concernant ces droits par écrit ou en
      utilisant une adresse de courrier électronique enregistrée (KEP), une
      signature électronique sécurisée, une signature mobile, ou via une adresse
      de courrier électronique précédemment notifiée au responsable des données
      par la personne concernée et enregistrée dans le système du responsable
      des données, ou via un logiciel ou une application développée à cet effet.
      Vous pouvez également envoyer vos demandes via le "Formulaire de demande"
      sur le site web du responsable des données à l'adresse
      www.zirvecompany.net. Vos demandes seront évaluées et conclues dès que
      possible, et au plus tard dans les 30 (trente) jours suivant la date de
      notification. Bien qu'il soit essentiel de ne pas facturer de frais pour
      les demandes, le responsable des données se réserve le droit de facturer
      des frais en fonction du barème de frais fixé par la Commission de
      protection des données personnelles.
    </p>

    <p class="mt-4 text-sm text-gray-600">Ce texte a été traduit du turc.</p>
  </div>
</div>
<div *ngIf="activeLang === 'RU'">
  <div class="container mx-auto px-4 py-8">
    <h1 class="text-3xl font-bold mb-5">
      Политика использования файлов cookie на сайте
    </h1>
    <h2 class="text-xl font-semibold mb-4">
      Zirve International Makine San. Tic. Ltd. Şti.
    </h2>
    <h3 class="text-2xl font-semibold mb-4">
      ПОЛИТИКА ИСПОЛЬЗОВАНИЯ ФАЙЛОВ COOKIE НА САЙТЕ
    </h3>

    <section class="mb-6">
      <p class="mb-4">
        Мы, Zirve International Makine San. Tic. Ltd. Şti. (контролер данных),
        используем различные файлы данных cookie для обеспечения безопасного и
        полного использования нашими пользователями наших интернет-услуг
        (веб-услуг), хранения настраиваемых предпочтений в пользовательском
        интерфейсе, улучшения пользовательского опыта, сбора статистической
        информации и защиты конфиденциальности наших посетителей. Персональные
        данные собираются через файлы cookie под контролем контролера данных.
      </p>
      <p class="mb-4">
        Посещая наш сайт, вы соглашаетесь на использование файлов cookie в
        соответствии с настоящей политикой использования файлов cookie на сайте.
        Если вы хотите отключить файлы cookie на нашем сайте, вы можете изменить
        настройки вашего браузера. Однако отключение файлов cookie в браузере
        может повлиять на ваш пользовательский опыт на нашем сайте. В отсутствие
        постоянных или сессионных файлов cookie вы можете продолжать
        просматривать наш сайт с некоторыми отсутствующими компонентами и
        ограниченными возможностями доступа. Мы рекомендуем разрешить
        использование файлов cookie для полного использования нашего сайта и
        приложения.
      </p>
      <p class="mb-4">
        Контролер данных может обрабатывать персональные данные, собранные через
        файлы cookie, при условии выполнения условий, указанных в статье 5/2
        Закона о защите персональных данных № 6698 (Закон) (юридические причины)
        или с явного согласия субъекта данных.
      </p>
      <p class="mb-4">
        Как контролер данных, мы можем отменить, изменить типы или функции
        файлов cookie, которые мы используем на нашем сайте и в наших
        приложениях. Любые обновления, внесенные в наш текст раскрытия
        информации, вступят в силу после их публикации на нашем сайте и
        веб-приложениях.
      </p>
    </section>

    <section class="mb-6">
      <h3 class="text-lg font-semibold mb-3">
        Какие типы файлов cookie мы используем на наших сайтах и в приложениях?
      </h3>
      <ul class="list-disc pl-5">
        <li class="mb-2">
          <strong>Сессионные файлы cookie:</strong> Используются для
          предотвращения частого повторного ввода паролей нашими пользователями
          при входе в систему. Сессионные файлы cookie предоставляют права
          свободного перемещения в течение определенного периода в рамках
          приложения и сайта.
        </li>
        <li class="mb-2">
          <strong>Файлы cookie визуальных предпочтений:</strong> Используются
          для предоставления персонализированного контента и опыта на основе
          поведения и выбора пользователей.
        </li>
        <li class="mb-2">
          <strong>Статистические файлы cookie:</strong> Используются для анализа
          привычек использования наших пользователей и гостей и определения
          статистики приложения.
        </li>
      </ul>
    </section>

    <section>
      <h3 class="text-lg font-semibold mb-3">
        Какие права у вас есть как у субъекта данных?
      </h3>
      <p class="mb-4">Согласно Закону, субъекты данных имеют право:</p>
      <ul class="list-disc pl-5 mb-4">
        <li class="mb-2">Узнать, обрабатываются ли их персональные данные,</li>
        <li class="mb-2">
          Запросить информацию о своих обрабатываемых персональных данных,
        </li>
        <li class="mb-2">
          Узнать цель обработки данных и используются ли их данные в
          соответствии с предполагаемой целью,
        </li>
        <li class="mb-2">
          Запросить исправление неполных или неправильно обработанных данных и
          запросить, чтобы исправление было сообщено третьим лицам, которым
          данные были переданы,
        </li>
        <li class="mb-2">
          Узнать третьих лиц, которым персональные данные передаются внутри
          страны или за рубежом,
        </li>
        <li class="mb-2">
          Запросить удаление или уничтожение собранных данных и запросить, чтобы
          это действие было сообщено третьим лицам, которым данные были
          переданы,
        </li>
        <li class="mb-2">
          Запросить компенсацию за ущерб, возникший в результате незаконной
          обработки данных,
        </li>
        <li class="mb-2">
          Возражать против появления результата, неблагоприятного для самого
          человека, путем анализа обработанных данных исключительно через
          автоматизированные системы.
        </li>
      </ul>
    </section>

    <p class="mt-6 text-sm text-gray-600">
      Вы можете подать свои запросы относительно этих прав в письменной форме
      или с использованием зарегистрированного электронного адреса (KEP),
      безопасной электронной подписи, мобильной подписи или через электронный
      адрес, ранее уведомленный контролеру данных соответствующим лицом и
      зарегистрированный в системе контролера данных, или через программное
      обеспечение или приложение, разработанное для этой цели. Вы также можете
      отправить свои запросы через "Форму запроса" на сайте контролера данных по
      адресу www.zirvecompany.net. Ваши запросы будут оценены и решены как можно
      скорее, и не позднее 30 (тридцати) дней с даты уведомления. Хотя основным
      является отсутствие платы за запросы, контролер данных оставляет за собой
      право взимать плату на основе тарифов, установленных Советом по защите
      персональных данных.
    </p>

    <p class="mt-4 text-sm text-gray-600">
      Этот текст был переведен с турецкого.
    </p>
  </div>
</div>
