<div class="relative w-full top-0 h-[4.6rem] bg-gray-900"></div>
<div *ngIf="activeLang === 'TR'">
  <div class="container mx-auto p-4">
    <h1 class="text-3xl font-bold mb-4">
      Site Kullanım Koşulları, Gizlilik Politikası ve Yasal Uyarı
    </h1>

    <p class="mb-4">
      Zirve International Makina San. Tic. Ltd. Şti. (“www.zirvecompany.net”)
      Web Sitesi’ne Hoş Geldiniz! Bu siteyi kullandığınızda aşağıdaki koşulları
      kabul etmiş sayılacaksınız. Bu koşulları kabul etmiyorsanız lütfen bu
      siteye girmeyiniz. www.zirvecompany.net web sitesinin yayınlanması, hiçbir
      kişi ve/veya kuruluşa herhangi bir konuda herhangi bir taahhüdü
      içermemektedir. Sitede yer alan bilgiler, kişi ve kuruluşlara yönelik
      talimat verilmesi, hukuken onların herhangi bir doğrultuya yönlendirmesi
      amaçlarını ve tebligat niteliğini taşımamaktadır.
    </p>

    <p class="mb-4">
      Web sitemizde yayınlanan çalışmalar, kaynak gösterilmek suretiyle
      yayımlanabilir; ancak bu bilgilerin ticari amaçlarla kullanımı Zirve
      International Makina San. Tic. Ltd. Şti.’nin yazılı iznine tabidir.
      www.zirvecompany.net web sitesinde yayınlanan bilgilerin güncel ve doğru
      bilgilerle yayınlanmasını temin etmek amacıyla elinden gelen çabayı
      göstermekle birlikte, Zirve International Makina San. Tic. Ltd. Şti.’nin
      bu bilgilerin doğruluğu ve tamlığı konusunda açık veya zımni şekilde
      kefalet veya garanti verdiği yolunda hiçbir taahhüdü bulunmamaktadır.
    </p>

    <p class="mb-4">
      Kullanıcılar sisteme girmeleri ile birlikte, www.zirvecompany.net
      tarafından yayınlanan bilgilerin kullanımı sonucunda doğrudan veya dolaylı
      bir kayıp veya zarar doğması halinde, bu durumun Zirve International
      Makina San. Tic. Ltd. Şti.’ne hiçbir borç, sorumluluk veya mükellefiyet
      yüklemeyeceğini açık olarak kabul etmiş sayılırlar. Sitede yer alan bütün
      metinler ve/veya hizmetler, önceden kullanıcılara bildirimde bulunmaksızın
      Zirve International Makina San. Tic. Ltd. Şti. tarafından
      değiştirilebilir.
    </p>

    <p class="mb-4">
      www.zirvecompany.net web sitesi ön bilgi verilmeden kullanım dışı
      bırakılabilir. www.zirvecompany.net web sitesinde yayınlanmış bilgiler, ya
      da bilgilere dayanılarak yapılan işlemlerden veya siteye ulaşılamamasından
      doğan veya doğacak zarar ve/veya kayıplardan dolayı sorumluluk kabul
      edilmez. İnsan hataları, teknik veya diğer faktörler nedeniyle hata veya
      bilgi eksikliği/yanlışlığının veya güncellik yitirmesinin gerçekleşme
      olasılığı vardır. www.zirvecompany.net sistemde yer alan bilgilerdeki hata
      ve eksikliklerden hiçbir şekilde sorumlu bulunmamaktadır.
    </p>

    <p class="mb-4">
      Bu site Zirve International Makina San. Tic. Ltd. Şti.'ne ait olup site
      içerisindeki bilgilerin bir kısmı kendi bir kısmı da diğer kaynaklardan
      sağlanarak Zirve International Makina San. Tic. Ltd. Şti. tarafından
      yayınlanmaktadır. Sitemizden bağlantı yapılarak ulaşılan diğer sitelerdeki
      bilgiler, ilgili kuruluşlar tarafından yayınlanmakta olup, Zirve
      International Makina San. Tic. Ltd. Şti.’ni hiçbir şekilde
      bağlamamaktadır.
    </p>

    <h2 class="text-2xl font-bold mb-4">
      Gizlilik Politikası ve Güvenlik Politikası
    </h2>

    <p class="mb-4">
      Zirve International Makina San. Tic. Ltd. Şti. (“www.zirvecompany.net”)
      Zirve International Makina San. Tic. Ltd. Şti., internet sitesini ziyaret
      eden herkesin özel hayatına saygı göstermektedir. Site ziyaretçileri
      hakkında toplanan her türlü bilgi öncelikle site ziyaretçilerinin talep
      ettikleri veya edebilecekleri hizmetleri sunmak için ve sonra da şirket
      olarak daha iyi hizmet verebilmek amacıyla kullanılmaktadır. Zirve
      International Makina San. Tic. Ltd. Şti. hiçbir suretle bu amaçla toplanan
      kişisel bilgileri üçüncü kişi ve/veya kurumlar ile başka amaçlarla
      paylaşmamayı taahhüt eder. Kişisel olarak site ziyaretçileri olarak
      sizlerle ilgili hangi bilgilere sahip olduğunu öğrenme ve gerekirse
      düzeltmeler yapma hakkına sahipsiniz; ayrıca bu bilgilerin
      kullanılmamasını talep hakkına sahipsiniz. Talepleriniz güvenlik, mali
      veya hukuki sebepler ile yerine getirilemeyebilir.
    </p>

    <p class="mb-4">
      www.zirvecompany.net’in sayfalarından birinde veya daha fazlasında,
      www.zirvecompany.net harici internet sitelerine bağlantılar olabilir.
      Zirve International Makina San. Tic. Ltd. Şti., kendisinin işletmediği bu
      sitelerin kendilerine özgü gizlilik politikalarından hiçbir şekilde
      sorumlu tutulamaz. Internet adresi www.zirvecompany.net ile başlamayan
      siteler www.zirvecompany.net ile ilişkisi olmayan sitelerdir. Bu siteyi
      kullanarak Zirve International Makina San. Tic. Ltd. Şti.’nin Gizlilik
      Politikasını kabul etmiş sayılmaktasınız.
    </p>

    <p class="mb-4">
      Zirve International Makina San. Tic. Ltd. Şti. her zaman bu politikada
      değişiklik, ilave veya çıkartma yapma hakkını saklı tutar. Bu hüküm ve
      şartlarda yapılan değişikliklerin ardından bu web sitesini kullanmaya
      devam etmeniz, bu değişiklikleri kabul ettiğiniz anlamına gelmektedir.
    </p>

    <h2 class="text-2xl font-bold mb-4">Yasal Uyarı</h2>

    <p class="mb-4">
      Zirve International Makina San. Tic. Ltd. Şti. <br />
      <strong>Şirket Bilgileri:</strong> <br />
      - Adı: Zirve International Makina San. Tic. Ltd. Şti. <br />
      - Adresi: Sanayi Mah. 60352 sok. NO: 29 Şehitkamil, Gaziantep, Türkiye
      <br />
      - Telefon: +905389280007 <br />
      - E-posta: info@zirvecompany.com <br />
      - Web Sitesi: www.zirvecompany.net <br />
      - Sanayi Sicil No: 762376 <br />
      - Ticaret Sicil No: 42776 <br />
      - Ticaret Oda Sicil No: 43181 <br />
      - Vergi No: 9980773075 <br />
      - Firma Yetkilisi: Amer HADRI
    </p>
    <h1 class="text-3xl font-bold mb-4">Hukuki Uyarı</h1>

    <p class="mb-4">
      Bu web sitesi ve içeriği Zirve International Makina San. Tic. Ltd. Şti.
      (bundan böyle "Şirket" olarak anılacaktır) tarafından sağlanmaktadır. Bu
      web sitesine erişerek ve kullanarak, aşağıdaki şartları kabul etmiş
      sayılırsınız. Bu şartları kabul etmiyorsanız, lütfen bu web sitesine
      girmeyiniz.
    </p>

    <ol class="list-decimal list-inside mb-4">
      <li>
        <strong>İçerik Sahipliği:</strong> Tüm içerik, metin, grafik, logo,
        resim ve yazılım dahil olmak üzere, Şirket'in mülkiyetindedir ve telif
        hakkı, ticari marka ve diğer ilgili yasalarla korunmaktadır. İçeriğin
        izinsiz kullanımı kesinlikle yasaktır.
      </li>
      <li>
        <strong>Sorumluluk Sınırlaması:</strong> Şirket, bu web sitesindeki
        bilgilerin doğruluğunu ve güvenilirliğini sağlamak için çaba
        göstermektedir. Ancak, Şirket bilgilerin eksiksizliği, doğruluğu veya
        güncelliği konusunda herhangi bir garanti vermemektedir ve hiçbir hata
        veya eksiklikten sorumlu değildir. Kullanıcılar, web sitesini kendi
        sorumlulukları altında kullanmayı kabul ederler.
      </li>
      <li>
        <strong>Şube veya İştirak Bulunmaması:</strong> Şirket'in herhangi bir
        şubesi, iştiraki veya bağlı kuruluşu bulunmamaktadır. Şirket, kendisini
        temsil ettiğini veya Şirket ile ilişkili olduğunu iddia eden üçüncü
        şahıslar tarafından yapılan herhangi bir beyan, işlem veya iletişimden
        sorumlu değildir. Bu tür yetkisiz beyan veya işlemler derhal Şirket'e
        bildirilmelidir.
      </li>
      <li>
        <strong>Uygulanacak Hukuk:</strong> Bu hukuki uyarı, Türkiye Cumhuriyeti
        kanunlarına tabidir ve bu kanunlara göre yorumlanacaktır. Bu hukuki
        uyarıdan kaynaklanan veya bununla bağlantılı herhangi bir uyuşmazlık,
        Gaziantep, Türkiye mahkemelerinin münhasır yargı yetkisine tabi
        olacaktır.
      </li>
      <li>
        <strong>Hukuki Uyarıda Değişiklikler:</strong> Şirket, bu hukuki uyarıyı
        herhangi bir zamanda değiştirme hakkını saklı tutar. Kullanıcılara bu
        hukuki uyarıyı periyodik olarak gözden geçirmeleri ve yapılan
        değişikliklerden haberdar olmaları önerilir.
      </li>
    </ol>

    <p class="mb-4">
      <strong>İletişim Bilgileri:</strong> Herhangi bir hukuki soru veya endişe
      için bizimle iletişime geçin:
      <br />
      - E-posta:
      <a
        href="mailto:info@zirvecompany.com"
        class="text-blue-500 hover:underline"
        >info@zirvecompany.com</a
      >
      <br />
      - Adres: Sanayi Mah. 60352 sok. NO: 29 Şehitkamil, Gaziantep, Türkiye
    </p>

    <p class="mb-4">
      Bu web sitesini kullanmaya devam ederek, bu hukuki uyarıda belirtilen
      şartları ve koşulları kabul ettiğinizi beyan edersiniz.
    </p>
  </div>
</div>
<div *ngIf="activeLang === 'EN'">
  <div class="mx-auto max-w-2xl py-12 px-4 sm:px-6 lg:px-8">
    <h2 class="text-2xl font-bold mb-4">Privacy Policy</h2>

    <div class="prose prose-lg">
      <p>
        Zirve International is committed to protecting the privacy and security
        of our customers' and website visitors' personal information. This
        policy outlines how we collect, use, and protect the information we
        obtain.
      </p>

      <h3 class="font-bold mt-4 mb-2">Collection of Personal Information:</h3>
      <p>
        We collect personal information that you provide to us when you register
        on our website, request information, or make a purchase. This
        information may include, but is not limited to, your name, email
        address, phone number, and mailing address.
      </p>

      <h3 class="font-bold mt-4 mb-2">Use of Personal Information:</h3>
      <ul>
        <li>Processing orders and providing requested services.</li>
        <li>Communicating with customers about orders and services.</li>
        <li>
          Enhancing your experience on our website by personalizing content and
          offers.
        </li>
        <li>
          Sending promotional information and updates about our products and
          services.
        </li>
      </ul>

      <h3 class="font-bold mt-4 mb-2">Sharing of Personal Information:</h3>
      <p>
        We do not sell, rent, or trade personal information with third parties.
        We may share information with service providers who work on our behalf,
        provided they adhere to our privacy policy and use the information only
        for specified purposes.
      </p>

      <h3 class="font-bold mt-4 mb-2">Protection of Personal Information:</h3>
      <p>
        We implement appropriate security measures to protect personal
        information from unauthorized access, alteration, disclosure, and
        destruction. These measures include encryption technologies, firewalls,
        and identity verification procedures.
      </p>

      <h3 class="font-bold mt-4 mb-2">Cookies:</h3>
      <p>
        We use cookies to enhance your experience on our website. Cookies are
        small text files stored on your device when you visit our site. They
        allow us to track your preferences and provide tailored content. You can
        manage cookies through your browser settings.
      </p>

      <h3 class="font-bold mt-4 mb-2">Your Rights:</h3>
      <p>
        You have the right to access, update, and correct the personal
        information we hold about you. If you wish to exercise these rights,
        please contact us using the information provided below.
      </p>

      <h3 class="font-bold mt-4 mb-2">Changes to the Privacy Policy:</h3>
      <p>
        We may update this privacy policy from time to time to reflect changes
        in our practices or legal requirements. We will post the updated policy
        on our website with the effective date.
      </p>
    </div>

    <h2 class="text-2xl font-bold mt-12 mb-4">Legal Notice</h2>

    <div class="prose prose-lg">
      <p>
        ZIRVE INTERNATIONAL MAKINA ITHALAT IHRACAT SANAYI VE TICARET LIMITED
        SIRKETI<br />
        SAN.VE TIC.LTD.STI<br />
        SANAYI MAH 60352 SOK. NO: 29<br />
        SEHITKAMIL GAZIANTEP TURKEY<br />
        Tel: +905389280007<br />
        Mail: info@zirvecompany.com<br />
        Website: <a href="http://www.zirvecompany.net">www.zirvecompany.net</a
        ><br />
        Industrial Registry Number: 762376<br />
        Trade Registry Number: 42776<br />
        Chamber of Commerce Registry Number: 43181<br />
        VAT Number: 9980773075<br />
        Company Owner: Amer HADRI
      </p>

      <h3 class="font-bold mt-4 mb-2">Website Use:</h3>
      <p>
        By using this website, you agree to comply with the following terms and
        conditions of use, which govern Zirve International’s relationship with
        you in relation to this website. If you disagree with any part of these
        terms, please do not use our website.
      </p>

      <h3 class="font-bold mt-4 mb-2">Intellectual Property Rights:</h3>
      <p>
        All content published on this website, including text, graphics, logos,
        icons, images, audio materials, and digital downloads, is the property
        of Zirve International and is protected by copyright and trademark laws.
        No part of this content may be reproduced without the express written
        permission of the company.
      </p>

      <h3 class="font-bold mt-4 mb-2">External Links:</h3>
      <p>
        Our website may contain links to external websites. Zirve International
        is not responsible for the content of these external sites or for any
        loss or damage that may arise from your use of them.
      </p>

      <h3 class="font-bold mt-4 mb-2">Privacy:</h3>
      <p>
        We are committed to protecting your privacy. Please review our privacy
        policy to learn more about how we collect and use personal information.
      </p>

      <h3 class="font-bold mt-4 mb-2">Limitation of Liability:</h3>
      <p>
        Zirve International strives to ensure that the information provided on
        this website is accurate and up to date. However, we make no
        representations or warranties of any kind, express or implied, about the
        completeness, accuracy, reliability, suitability, or availability of the
        website or the information, products, services, or related graphics
        contained on the website for any purpose. Any reliance you place on such
        information is therefore strictly at your own risk.
      </p>

      <h3 class="font-bold mt-4 mb-2">Changes to Terms:</h3>
      <p>
        Zirve International reserves the right to modify these terms and
        conditions at any time without prior notice. It is your responsibility
        to review this page regularly to stay informed of any changes.
      </p>

      <h3 class="font-bold mt-4 mb-2">Governing Law:</h3>
      <p>
        These terms and conditions are governed by and construed in accordance
        with the laws of Türkiye, and any disputes arising out of or in
        connection with the use of the website are subject to the exclusive
        jurisdiction of the courts in Türkiye.
      </p>
    </div>
  </div>
</div>
<div *ngIf="activeLang === 'AR'" dir="rtl">
  <div class="mx-auto max-w-2xl py-12 px-4 sm:px-6 lg:px-8">
    <h1 class="text-3xl font-bold text-center mb-8">
      سياسة الخصوصية والإشعار القانوني لشركة زيروا انترناشيونال
    </h1>

    <div class="prose prose-lg">
      <h2 class="font-bold mb-4">سياسة الخصوصية</h2>
      <p>
        تلتزم شركة زيروا انترناشيونال بحماية خصوصية وأمان المعلومات الشخصية
        لعملائنا وزوار موقعنا الإلكتروني. توضح هذه السياسة كيفية جمع واستخدام
        وحماية المعلومات التي نحصل عليها.
      </p>

      <h3 class="font-bold mt-6 mb-2">جمع المعلومات الشخصية</h3>
      <p>
        نقوم بجمع المعلومات الشخصية التي تزودنا بها عند التسجيل في موقعنا، أو
        طلب المعلومات، أو إجراء عملية شراء. تشمل هذه المعلومات، على سبيل المثال
        لا الحصر، الاسم، عنوان البريد الإلكتروني، رقم الهاتف، والعنوان البريدي.
      </p>

      <h3 class="font-bold mt-6 mb-2">استخدام المعلومات الشخصية</h3>
      <p>نستخدم المعلومات الشخصية للأغراض التالية:</p>
      <ul class="list-disc ml-6">
        <li>معالجة الطلبات وتقديم الخدمات المطلوبة.</li>
        <li>التواصل مع العملاء بشأن الطلبات والخدمات.</li>
        <li>
          تحسين تجربتك على موقعنا من خلال تخصيص المحتوى والعروض المقدمة لك.
        </li>
        <li>
          إرسال المعلومات الترويجية والتحديثات المتعلقة بمنتجاتنا وخدماتنا.
        </li>
      </ul>

      <h3 class="font-bold mt-6 mb-2">مشاركة المعلومات الشخصية</h3>
      <p>
        نحن لا نبيع أو نؤجر أو نتبادل المعلومات الشخصية مع أطراف ثالثة. قد نشارك
        المعلومات مع مزودي الخدمات الذين يعملون نيابة عنا، بشرط أن يلتزموا
        بسياسة الخصوصية لدينا واستخدام المعلومات فقط للأغراض المحددة.
      </p>

      <h3 class="font-bold mt-6 mb-2">حماية المعلومات الشخصية</h3>
      <p>
        نتخذ إجراءات أمنية مناسبة لحماية المعلومات الشخصية من الوصول غير المصرح
        به، والتعديل، والإفصاح، والتدمير. تتضمن هذه الإجراءات تقنيات التشفير،
        والجدران النارية، وإجراءات التحقق من الهوية.
      </p>

      <h3 class="font-bold mt-6 mb-2">ملفات تعريف الارتباط (Cookies)</h3>
      <p>
        نستخدم ملفات تعريف الارتباط لتحسين تجربتك على موقعنا. ملفات تعريف
        الارتباط هي ملفات نصية صغيرة يتم تخزينها على جهازك عندما تزور موقعنا.
        تمكننا من تتبع تفضيلاتك وتقديم محتوى مخصص لك. يمكنك التحكم في ملفات
        تعريف الارتباط من خلال إعدادات المتصفح الخاص بك.
      </p>

      <h3 class="font-bold mt-6 mb-2">حقوقك</h3>
      <p>
        لديك الحق في الوصول إلى المعلومات الشخصية التي نحتفظ بها عنك وتحديثها
        وتصحيحها. إذا كنت ترغب في ممارسة هذه الحقوق، يرجى التواصل معنا عبر
        المعلومات المذكورة أدناه.
      </p>

      <h3 class="font-bold mt-6 mb-2">التغييرات على سياسة الخصوصية</h3>
      <p>
        قد نقوم بتحديث سياسة الخصوصية من وقت لآخر لتعكس التغييرات في ممارساتنا
        أو اللوائح القانونية. سنقوم بنشر السياسة المحدثة على موقعنا مع تاريخ
        السريان.
      </p>
    </div>

    <h2 class="text-2xl font-bold mb-4">الإشعار القانوني</h2>

    <div class="prose prose-lg">
      <ul class="list-disc ml-6">
        <li>
          <strong>الاسم:</strong> ZIRVE INTERNATIONAL MAKINA ITHALAT IHRACAT
          SANAYI VE TICARET LIMITED SIRKETI
        </li>
        <li>
          <strong>العنوان:</strong> SANAYI MAH 60352 SOK. NO: 29, SEHITKAMIL
          GAZIANTEP TURKEY
        </li>
        <li><strong>الهاتف:</strong> +905389280007</li>
        <li>
          <strong>البريد الإلكتروني:</strong>
          <a href="mailto:info@zirvecompany.com">info@zirvecompany.com</a>
        </li>
        <li>
          <strong>الموقع الإلكتروني:</strong>
          <a href="http://www.zirvecompany.net">www.zirvecompany.net</a>
        </li>
        <li><strong>رقم السجل الصناعي:</strong> 762376</li>
        <li><strong>رقم سجل التجارة:</strong> 42776</li>
        <li><strong>رقم غرفة التجارة:</strong> 43181</li>
        <li><strong>الرقم الضريبي:</strong> 9980773075</li>
        <li><strong>صاحب الشركة:</strong> Amer HADRI</li>
      </ul>
    </div>
    <div class="prose prose-lg">
      <p>
        باستخدامك لهذا الموقع، فإنك توافق على الالتزام بشروط وأحكام الاستخدام
        التالية، والتي تحكم علاقتك بشركة زيروا انترناشيونال فيما يتعلق بهذا
        الموقع. إذا كنت لا توافق على أي جزء من هذه الشروط، يرجى عدم استخدام
        موقعنا.
      </p>

      <h3 class="font-bold mt-4 mb-2">حقوق الملكية الفكرية:</h3>
      <p>
        جميع المحتويات المنشورة على هذا الموقع، بما في ذلك النصوص، الرسومات،
        الشعارات، الأيقونات، الصور، المواد الصوتية، والتنزيلات الرقمية، هي ملك
        لشركة زيروا انترناشيونال ومحمية بموجب قوانين حقوق الطبع والنشر والعلامات
        التجارية. لا يجوز إعادة إنتاج أي جزء من هذه المحتويات بدون إذن كتابي
        صريح من الشركة.
      </p>

      <h3 class="font-bold mt-4 mb-2">الروابط الخارجية:</h3>
      <p>
        قد يحتوي موقعنا على روابط لمواقع خارجية. شركة زيروا انترناشيونال ليست
        مسؤولة عن محتوى هذه المواقع الخارجية أو عن أي خسارة أو ضرر قد ينشأ عن
        استخدامك لها.
      </p>

      <h3 class="font-bold mt-4 mb-2">الخصوصية:</h3>
      <p>
        نلتزم بحماية خصوصيتك. يُرجى الاطلاع على
        <a href="#">سياسة الخصوصية</a> الخاصة بنا لمعرفة المزيد حول كيفية جمع
        واستخدام المعلومات الشخصية.
      </p>

      <h3 class="font-bold mt-4 mb-2">حدود المسؤولية:</h3>
      <p>
        تسعى شركة زيروا انترناشيونال لضمان أن تكون المعلومات المقدمة على هذا
        الموقع دقيقة وحديثة. ومع ذلك، لا نقدم أي تعهدات أو ضمانات من أي نوع،
        صريحة أو ضمنية، بشأن اكتمال أو دقة أو موثوقية أو ملاءمة أو توفر الموقع
        أو المعلومات أو المنتجات أو الخدمات أو الرسومات المرتبطة على الموقع لأي
        غرض كان. أي اعتماد تضعه على هذه المعلومات هو بالتالي على مسؤوليتك
        الخاصة.
      </p>

      <h3 class="font-bold mt-4 mb-2">التعديلات على الشروط:</h3>
      <p>
        تحتفظ شركة زيروا انترناشيونال بالحق في تعديل هذه الشروط والأحكام في أي
        وقت دون إشعار مسبق. من مسؤوليتك مراجعة هذه الصفحة بانتظام لمعرفة أي
        تغييرات.
      </p>

      <h3 class="font-bold mt-4 mb-2">القانون الحاكم:</h3>
      <p>
        تخضع هذه الشروط والأحكام وتفسر وفقًا لقوانين Türkiye، وتخضع لأي نزاعات
        تنشأ عن أو تتعلق باستخدام الموقع لاختصاص المحاكم في Türkiye.
      </p>
    </div>
  </div>
</div>
<div *ngIf="activeLang === 'FR'">
  <div class="max-w-3xl mx-auto px-4 py-8">
    <!-- Politique de Confidentialité -->
    <section class="mb-8">
      <h2 class="text-3xl font-bold mb-4">
        Politique de Confidentialité de Zirve International
      </h2>
      <p class="mb-4">
        Zirve International s'engage à protéger la confidentialité et la
        sécurité des informations personnelles de nos clients et visiteurs de
        notre site web. Cette politique décrit comment nous collectons,
        utilisons et protégeons les informations que nous obtenons.
      </p>

      <h3 class="text-xl font-semibold mb-2">
        Collecte d'Informations Personnelles :
      </h3>
      <p class="mb-4">
        Nous collectons les informations personnelles que vous nous fournissez
        lors de votre inscription sur notre site, de la demande d'informations
        ou de la réalisation d'un achat. Ces informations peuvent inclure, mais
        ne sont pas limitées à, votre nom, adresse e-mail, numéro de téléphone
        et adresse postale.
      </p>

      <h3 class="text-xl font-semibold mb-2">
        Utilisation des Informations Personnelles :
      </h3>
      <ul class="list-disc pl-4 mb-4">
        <li>Traitement des commandes et fourniture des services demandés.</li>
        <li>
          Communication avec les clients concernant les commandes et services.
        </li>
        <li>
          Amélioration de votre expérience sur notre site en personnalisant le
          contenu et les offres.
        </li>
        <li>
          Envoi d'informations promotionnelles et de mises à jour sur nos
          produits et services.
        </li>
      </ul>

      <h3 class="text-xl font-semibold mb-2">
        Partage des Informations Personnelles :
      </h3>
      <p>
        Nous ne vendons, louons ou échangeons pas les informations personnelles
        avec des tiers. Nous pouvons partager des informations avec des
        prestataires de services qui travaillent en notre nom, à condition
        qu'ils adhèrent à notre politique de confidentialité et utilisent les
        informations uniquement à des fins spécifiques.
      </p>

      <h3 class="text-xl font-semibold mb-2">
        Protection des Informations Personnelles :
      </h3>
      <p>
        Nous mettons en œuvre des mesures de sécurité appropriées pour protéger
        les informations personnelles contre tout accès non autorisé,
        modification, divulgation et destruction. Ces mesures comprennent des
        technologies de cryptage, des pare-feu et des procédures de vérification
        d'identité.
      </p>

      <h3 class="text-xl font-semibold mb-2">Cookies :</h3>
      <p>
        Nous utilisons des cookies pour améliorer votre expérience sur notre
        site. Les cookies sont de petits fichiers texte stockés sur votre
        appareil lorsque vous visitez notre site. Ils nous permettent de suivre
        vos préférences et de fournir un contenu personnalisé. Vous pouvez gérer
        les cookies via les paramètres de votre navigateur.
      </p>

      <h3 class="text-xl font-semibold mb-2">Vos Droits :</h3>
      <p>
        Vous avez le droit d'accéder aux informations personnelles que nous
        détenons sur vous, de les mettre à jour et de les corriger. Si vous
        souhaitez exercer ces droits, veuillez nous contacter en utilisant les
        informations fournies ci-dessous.
      </p>

      <h3 class="text-xl font-semibold mb-2">
        Modifications de la Politique de Confidentialité :
      </h3>
      <p>
        Nous pouvons mettre à jour cette politique de confidentialité de temps à
        autre pour refléter les changements dans nos pratiques ou les exigences
        légales. Nous publierons la politique mise à jour sur notre site avec la
        date d'entrée en vigueur.
      </p>
    </section>

    <!-- Avis Légal -->
    <section>
      <h2 class="text-3xl font-bold mb-4">
        Avis Légal pour Zirve International
      </h2>
      <p class="mb-4">
        ZIRVE INTERNATIONAL MAKINA ITHALAT IHRACAT SANAYI VE TICARET LIMITED
        SIRKETI
      </p>
      <p class="mb-4">SAN.VE TIC.LTD.STI</p>
      <p class="mb-4">SANAYI MAH 60352 SOK. NO: 29</p>
      <p class="mb-4">SEHITKAMIL GAZIANTEP TURKEY</p>
      <p class="mb-4">Tel: +905389280007</p>
      <p class="mb-4">Mail: info@zirvecompany.com</p>
      <p class="mb-4">
        Website:
        <a href="http://www.zirvecompany.net" class="text-blue-500"
          >www.zirvecompany.net</a
        >
      </p>
      <p class="mb-4">Industrial Registry Number: 762376</p>
      <p class="mb-4">Trade Registry Number: 42776</p>
      <p class="mb-4">Chamber of Commerce Registry Number: 43181</p>
      <p class="mb-4">VAT Number: 9980773075</p>
      <p class="mb-4">Company Owner: Amer HADRI</p>

      <h3 class="text-xl font-semibold mb-2">Utilisation du site web :</h3>
      <p>
        En utilisant ce site web, vous acceptez de vous conformer aux termes et
        conditions d'utilisation suivants, qui régissent la relation de Zirve
        International avec vous en ce qui concerne ce site web. Si vous n'êtes
        pas d'accord avec une partie de ces termes, veuillez ne pas utiliser
        notre site.
      </p>

      <h3 class="text-xl font-semibold mb-2">
        Droits de propriété intellectuelle :
      </h3>
      <p>
        Tout le contenu publié sur ce site web, y compris les textes,
        graphiques, logos, icônes, images, matériaux audio et téléchargements
        numériques, est la propriété de Zirve International et est protégé par
        les lois sur le droit d'auteur et les marques. Aucune partie de ce
        contenu ne peut être reproduite sans l'autorisation écrite expresse de
        l'entreprise.
      </p>

      <h3 class="text-xl font-semibold mb-2">Liens externes :</h3>
      <p>
        Notre site web peut contenir des liens vers des sites externes. Zirve
        International n'est pas responsable du contenu de ces sites externes ni
        de toute perte ou dommage pouvant résulter de votre utilisation de ces
        sites.
      </p>

      <h3 class="text-xl font-semibold mb-2">Confidentialité :</h3>
      <p>
        Nous nous engageons à protéger votre confidentialité. Veuillez consulter
        notre politique de confidentialité pour en savoir plus sur la façon dont
        nous collectons et utilisons les informations personnelles.
      </p>

      <h3 class="text-xl font-semibold mb-2">Limitation de responsabilité :</h3>
      <p>
        Zirve International s'efforce de garantir que les informations fournies
        sur ce site web sont exactes et à jour. Cependant, nous ne faisons
        aucune déclaration ou garantie de quelque nature que ce soit, expresse
        ou implicite, quant à l'exhaustivité, l'exactitude, la fiabilité, la
        pertinence ou la disponibilité du site web ou des informations,
        produits, services ou graphiques connexes contenus sur le site web à
        quelque fin que ce soit. Toute confiance que vous accordez à ces
        informations est donc strictement à vos propres risques.
      </p>

      <h3 class="text-xl font-semibold mb-2">Modifications des termes :</h3>
      <p>
        Zirve International se réserve le droit de modifier ces termes et
        conditions à tout moment sans préavis. Il est de votre responsabilité de
        consulter cette page régulièrement pour rester informé de tout
        changement.
      </p>

      <h3 class="text-xl font-semibold mb-2">Droit applicable :</h3>
      <p>
        Ces termes et conditions sont régis par et interprétés conformément aux
        lois de Türkiye, et tout litige découlant de ou en relation avec
        l'utilisation du site web est soumis à la compétence exclusive des
        tribunaux de Türkiye.
      </p>
    </section>
  </div>
</div>
<div *ngIf="activeLang === 'RU'">
  <div class="max-w-4xl mx-auto px-4 py-8">
    <h1 class="text-3xl font-bold text-center mb-8">
      Политика конфиденциальности компании Zirve International
    </h1>

    <div class="p-6 mb-8">
      <p class="text-lg mb-4">
        Компания Zirve International обязуется защищать конфиденциальность и
        безопасность личной информации наших клиентов и посетителей сайта.
        Данная политика описывает, как мы собираем, используем и защищаем
        получаемую информацию.
      </p>

      <h2 class="text-xl font-semibold mb-4">Сбор личной информации</h2>
      <p>
        Мы собираем личную информацию, которую вы предоставляете нам при
        регистрации на нашем сайте, запросе информации или совершении покупки.
        Эта информация может включать, но не ограничивается вашим именем,
        адресом электронной почты, номером телефона и почтовым адресом.
      </p>

      <h2 class="text-xl font-semibold mb-4 mt-6">
        Использование личной информации
      </h2>
      <ul class="list-disc ml-8">
        <li>Обработка заказов и предоставление запрашиваемых услуг.</li>
        <li>Общение с клиентами по поводу заказов и услуг.</li>
        <li>
          Улучшение вашего опыта на нашем сайте путем персонализации контента и
          предложений.
        </li>
        <li>
          Отправка рекламной информации и обновлений о наших продуктах и
          услугах.
        </li>
      </ul>

      <h2 class="text-xl font-semibold mb-4 mt-6">
        Передача личной информации
      </h2>
      <p>
        Мы не продаем, не сдаем в аренду и не обмениваемся личной информацией с
        третьими сторонами. Мы можем передавать информацию поставщикам услуг,
        которые работают от нашего имени, при условии, что они соблюдают нашу
        политику конфиденциальности и используют информацию только для указанных
        целей.
      </p>

      <h2 class="text-xl font-semibold mb-4 mt-6">Защита личной информации</h2>
      <p>
        Мы принимаем соответствующие меры безопасности для защиты личной
        информации от несанкционированного доступа, изменения, раскрытия и
        уничтожения. Эти меры включают технологии шифрования, межсетевые экраны
        и процедуры проверки подлинности.
      </p>

      <h2 class="text-xl font-semibold mb-4 mt-6">Файлы cookie</h2>
      <p>
        Мы используем файлы cookie для улучшения вашего опыта на нашем сайте.
        Файлы cookie - это небольшие текстовые файлы, которые сохраняются на
        вашем устройстве при посещении нашего сайта. Они позволяют нам
        отслеживать ваши предпочтения и предоставлять персонализированный
        контент. Вы можете управлять файлами cookie через настройки вашего
        браузера.
      </p>

      <h2 class="text-xl font-semibold mb-4 mt-6">Ваши права</h2>
      <p>
        У вас есть право на доступ, обновление и исправление личной информации,
        которую мы храним о вас. Если вы хотите воспользоваться этими правами,
        пожалуйста, свяжитесь с нами, используя указанные ниже контактные
        данные.
      </p>

      <h2 class="text-xl font-semibold mb-4 mt-6">
        Изменения в политике конфиденциальности
      </h2>
      <p>
        Мы можем время от времени обновлять эту политику конфиденциальности,
        чтобы отразить изменения в наших практиках или законодательные
        требования. Мы опубликуем обновленную политику на нашем сайте с
        указанием даты вступления в силу.
      </p>
    </div>

    <div class="p-6 mb-8">
      <h2 class="text-2xl font-bold mb-4">
        Юридическое уведомление для Zirve International
      </h2>
      <p class="mb-2">
        ZIRVE INTERNATIONAL MAKINA ITHALAT IHRACAT SANAYI VE TICARET LIMITED
        SIRKETI
      </p>
      <p class="mb-2">SAN.VE TIC.LTD.STI</p>
      <p class="mb-2">SANAYI MAH 60352 SOK. NO: 29</p>
      <p class="mb-2">SEHITKAMIL GAZIANTEP TURKEY</p>
      <p class="mb-2">Tel: +905389280007</p>
      <p class="mb-2">Mail: info@zirvecompany.com</p>
      <p class="mb-2">
        Website:
        <a href="http://www.zirvecompany.net" class="text-blue-500"
          >www.zirvecompany.net</a
        >
      </p>
      <p class="mb-2">Industrial Registry Number: 762376</p>
      <p class="mb-2">Trade Registry Number: 42776</p>
      <p class="mb-2">Chamber of Commerce Registry Number: 43181</p>
      <p>VAT Number: 9980773075</p>
      <p>Company Owner: Amer HADRI</p>
    </div>

    <div class="p-6">
      <h2 class="text-2xl font-bold mb-4">Использование сайта</h2>
      <p>
        Используя этот сайт, вы соглашаетесь соблюдать следующие условия
        использования, которые регулируют отношения Zirve International с вами в
        отношении этого сайта. Если вы не согласны с какой-либо частью этих
        условий, пожалуйста, не используйте наш сайт.
      </p>

      <h3 class="text-lg font-semibold mt-4 mb-2">
        Права интеллектуальной собственности
      </h3>
      <p>
        Весь контент, опубликованный на этом сайте, включая текст, графику,
        логотипы, иконки, изображения, аудиоматериалы и цифровые загрузки,
        является собственностью Zirve International и защищен законами об
        авторском праве и товарных знаках. Никакая часть этого контента не может
        быть воспроизведена без явного письменного разрешения компании.
      </p>

      <h3 class="text-lg font-semibold mt-4 mb-2">Внешние ссылки</h3>
      <p>
        Наш сайт может содержать ссылки на внешние сайты. Zirve International не
        несет ответственности за контент этих внешних сайтов или за любые убытки
        или повреждения, которые могут возникнуть в результате их использования.
      </p>

      <h3 class="text-lg font-semibold mt-4 mb-2">Конфиденциальность</h3>
      <p>
        Мы обязуемся защищать вашу конфиденциальность. Пожалуйста, ознакомьтесь
        с нашей политикой конфиденциальности, чтобы узнать больше о том, как мы
        собираем и используем личную информацию.
      </p>

      <h3 class="text-lg font-semibold mt-4 mb-2">
        Ограничение ответственности
      </h3>
      <p>
        Zirve International стремится обеспечивать точность и актуальность
        информации, предоставляемой на этом сайте. Однако мы не делаем никаких
        заявлений или гарантий любого рода, явных или подразумеваемых,
        относительно полноты, точности, надежности, пригодности или доступности
        сайта или информации, продуктов, услуг или связанных графиков,
        содержащихся на сайте, для любой цели. Любая зависимость, которую вы
        возлагаете на такую информацию, строго на ваш собственный риск.
      </p>

      <h3 class="text-lg font-semibold mt-4 mb-2">Изменения условий</h3>
      <p>
        Zirve International оставляет за собой право изменять эти условия
        использования в любое время без предварительного уведомления. Ваша
        ответственность — регулярно просматривать эту страницу, чтобы быть в
        курсе любых изменений.
      </p>

      <h3 class="text-lg font-semibold mt-4 mb-2">
        Применимое законодательство
      </h3>
      <p>
        Эти условия использования регулируются и толкуются в соответствии с
        законами Türkiye, и любые споры, возникающие из использования сайта,
        подлежат исключительной юрисдикции судов Türkiye.
      </p>
    </div>
  </div>
</div>
