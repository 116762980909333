import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { AuthUtils } from './auth.utils';
import { UserService } from 'app/user/user.service';
import { environment } from 'environments/environment';


@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private _user: any;
    public _authenticated: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        if (typeof document === 'undefined') {
            return; // Not running in the browser, do nothing
        }
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        if (typeof document === 'undefined') {
            return; // Not running in the browser, do nothing
        }
        return localStorage.getItem('accessToken') ?? '';
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


    isLoggedIn() {
        return this._user != null;
    }


    signIn(credentials: { email: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post(environment.baseUrl2 + 'users/login', credentials).pipe(
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.accessToken = response.accessToken;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;

                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<boolean> {
        return this._httpClient.get(`${environment.baseUrl2}users/check/JWT`, {
            headers: new HttpHeaders().set('Authorization', `Bearer ${this.accessToken}`)
        }).pipe(
            switchMap((response: any) => {
                if (response.accessToken) {
                    this.accessToken = response.accessToken;
                    this._authenticated = true;
                    this._userService.user = response.user;
                    return of(true);
                } else {
                    this._authenticated = false; // Update auth state
                    return of(false);
                }
            }),
            catchError(error => {
                console.error('Error authenticating token:', error);
                this._authenticated = false; // Update auth state
                return of(false);
            })
        );
    }


    /**
     * Sign out
     */
    signOut(): Observable<any> {
        localStorage.removeItem('accessToken');
        this._authenticated = false;
        this._userService.user = undefined;
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user): Observable<any> {
        return this._httpClient.post(environment.baseUrl2 + 'users/signup', user);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {

        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }
}