import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { DomSanitizer, Meta, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { HreflangService } from 'app/services/hreflang.service';
import { Equipment } from 'app/shared/equipment';
import { Machine } from 'app/shared/machine';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-machines',
  templateUrl: './machines.component.html',
  styleUrls: ['./machines.component.scss']
})
export class MachinesComponent implements OnInit, OnDestroy {
  machines$: Observable<Machine[]>;
  equipment: Equipment;
  activeLang: string = 'EN';
  firstRun: boolean;
  title: string = 'Machines Page';
  private langChangesSubscription: Subscription;

  constructor(
    public router: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: object,
    private _translocoService: TranslocoService,
    private meta: Meta,
    private hreflangService: HreflangService, private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.getActiveLang();
    if (isPlatformBrowser(this.platformId)) {
      this.machines$ = this.router.data.pipe(
        map((data: any) => {
          const machines: Machine[] = data.machines.machines;
          this.equipment = data.machines.cate;
          return this.sortMachinesByIds(machines, data.machines.cate.sortedMachines);
        })
      );
      this.selectRandomMachines();
    }
  }

  private sortMachinesByIds(machines: Machine[], ids: string[]): Machine[] {
    const idOrderMap = new Map<string, number>();
    ids.forEach((id, index) => idOrderMap.set(id, index));
    return machines.sort((a, b) => {
      const indexA = idOrderMap.get(a._id) ?? -1;
      const indexB = idOrderMap.get(b._id) ?? -1;
      return indexA - indexB;
    });
  }


  getActiveLang() {
    this.langChangesSubscription = this._translocoService.langChanges$.subscribe((activeLang) => {
      if (this.activeLang !== activeLang || !this.firstRun) {
        this.deleteSchemaMeta();
        setTimeout(() => {
          let url = this.router.snapshot.params['id'];
          this.hreflangService.updateHreflangTags(activeLang, `machines/${url}`);
        }, 1000);
        this.firstRun = true;
      }
      this.activeLang = activeLang;
    });
  }

  selectRandomMachines() {
    this.machines$.subscribe((machines: Machine[]) => {
      let selectedMachines: Machine[];
      const length = machines.length;
      if (length > 5) {
        selectedMachines = this.getRandomSubset(machines, 5, 5);
      } else if (length === 1) {
        selectedMachines = this.getRandomSubset(machines, 1, 1);
      }
      else if (length === 3) {
        selectedMachines = this.getRandomSubset(machines, 2, 2);
      }
      else if (length === 4) {
        selectedMachines = this.getRandomSubset(machines, 3, 3);
      } else {
        selectedMachines = this.getRandomSubset(machines, 1, length);
      }
      localStorage.setItem('selectedMachines', JSON.stringify(selectedMachines));
    });
  }

  private getRandomSubset(arr: any[], minSize: number, maxSize: number): any[] {
    const size = Math.floor(Math.random() * (maxSize - minSize + 1)) + minSize;
    const shuffled = arr.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, size);
  }

  getSafeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  ngOnDestroy() {
    if (this.langChangesSubscription) {
      this.langChangesSubscription.unsubscribe();
    }
    // Delete Robots
    const robotTags = this.meta.getTags('name="robots"');
    if (robotTags.length > 0) {
      robotTags.forEach(tag => {
        try {
          this.meta.removeTagElement(tag);
        } catch (error) {
          console.error('Error removing robot tag:', error);
        }
      });
    }

    this.deleteSchemaMeta();
  }

  deleteSchemaMeta() {
    // Delete Hreflang
    const hreflangTags = this.meta.getTags('name="hreflang"');
    if (hreflangTags.length > 0) {
      hreflangTags.forEach(tag => {
        try {
          this.meta.removeTagElement(tag);
        } catch (error) {
          console.error('Error removing hreflang tag:', error);
        }
      });
    }

    // Delete Canonical
    try {
      this.meta.removeTag('rel=canonical');
    } catch (error) {
      console.error('Error removing canonical tag:', error);
    }
  }



}
