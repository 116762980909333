import { Injectable } from '@angular/core';
import {
    Resolve,
    ActivatedRouteSnapshot
} from '@angular/router';
import { GeneralService } from 'app/services/general.service';
import { BagClass } from 'app/shared/bag';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})


@Injectable({ providedIn: 'root' })
export class ByBagResolver implements Resolve<BagClass> {
    constructor(private generalService: GeneralService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<BagClass> {
        const id = route.params['id'];
        return this.generalService.getItemsWithQuery(`bag_product/search/${id}`, { type: 'bag' }); // Send as query parameter
    }
}


@Injectable({ providedIn: 'root' })
export class ByProductResolver implements Resolve<BagClass> {
    constructor(private generalService: GeneralService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<BagClass> {
        const id = route.params['id'];
        return this.generalService.getItemsWithQuery(`bag_product/search/${id}`, { type: 'product' }); // Send as query parameter
    }
}



@Injectable({ providedIn: 'root' })
export class ByProductsBagsResolver implements Resolve<BagClass[]> {
    constructor(private generalService: GeneralService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<BagClass[]> {
        const type = route.params['type'];
        return this.generalService.getItemsWithQuery('bag_product', { type: type }); // Send as query parameter
    }
}





