<div class="flex justify-center items-center p-5 overflow-hidden">
  <div class="flex justify-center items-center">
    <div *ngIf="!showImageViewer && startLoadImages">
      <div
        class="flex items-center justify-center h-28 w-28 mx-auto rounded-full text-white bg-[#E60027]"
      >
        <span class="font-bold"> Loading... </span>
      </div>
      <img
        class="block md:hidden w-full"
        src="assets/images/phrotate.gif"
        alt=""
      />
    </div>
    <div [hidden]="!showImageViewer">
      <ng-container class="flex">
        <img
          loading="lazy"
          class="center"
          [ngClass]="imgWidth"
          [src]="currentImagePath"
          style="cursor: pointer"
        />
      </ng-container>
    </div>
    <canvas
      (mousedown)="onMouseDown()"
      (mousemove)="onMouseMove($event)"
      (mouseup)="onMouseUp()"
      (touchstart)="onTouchStart($event)"
      (touchmove)="onTouchMove($event)"
      (touchend)="onTouchEnd()"
      #canvas
      class="absolute h-[42rem] w-full top-0 cursor-pointer"
    ></canvas>
  </div>
</div>
