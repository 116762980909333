import { Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import { isPlatformBrowser } from '@angular/common';
import { LanguageService } from 'app/services/language.service';
import { UserService } from 'app/user/user.service';
import { AuthService } from 'app/auth/auth.service';
import { AppComponent } from 'app/app.component';
import { SidenavService } from 'app/services/sidenav.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        opacity: 1,
        transform: 'translateY(0)'
      })),
      state('closed', style({
        opacity: 0,
        transform: 'translateY(-10px)'
      })),
      transition('open => closed', [
        animate('0.2s')
      ]),
      transition('closed => open', [
        animate('0.2s')
      ])
    ])
  ]
})
export class HeaderComponent implements OnInit {
  isOpen: boolean = false;
  availableLangs: AvailableLangs;
  activeLang: string = 'EN';
  showClass = false;
  flagCodes: any;
  user: any = undefined;
  constructor(private _translocoService: TranslocoService,
    private langService: LanguageService,
    private _userService: UserService,
    private appComponent: AppComponent,
    private sidenavS: SidenavService,
    public _authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: Object) {
  }


  ngOnInit(): void {
    this._userService.user$.subscribe((res) => {
      this.user = res;
    })
    this.availableLangs = this._translocoService.getAvailableLangs();
    this._translocoService.langChanges$.subscribe((activeLang) => {
      this.activeLang = activeLang;
    });
    this.flagCodes = {
      'EN': 'EN',
      'TR': 'TR',
      'AR': 'AR',
      'FR': 'FR',
      'RU': 'RU',
    };
    if (isPlatformBrowser(this.platformId)) {
      this.isScrolled = window.scrollY > 5;
    }
  }


  setActiveLang(lang: string): void {
    this._translocoService.setActiveLang(lang);
    this.langService.setLanguage(lang);
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  openAboutUsMenu() {
    this.showClass = !this.showClass;
  }

  isScrolled = false;
  @HostListener('window:scroll', [])
  onScroll() {
    if (isPlatformBrowser(this.platformId)) {
      this.isScrolled = window.scrollY > 5;
    }
  }


  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    const isOpen = this.isOpen || this.showClass; // Combine isOpen and showClass checks

    if (!isOpen) {
      return;
    }

    const target = event.target as HTMLElement;
    const isProfileClick = target.closest('.profileElement');
    const isAboutUsClick = target.closest('.aboutUsElement');

    if (!isProfileClick && !isAboutUsClick) {
      this.isOpen = false;
      this.showClass = false; // Close both dropdowns if clicked outside
    }
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  toggleSidenav() {
    const sidenav = this.appComponent.sidenavNav;
    this.sidenavS.setSidenav(sidenav);
    this.sidenavS.toggle();
  }

}
