import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit, OnDestroy {
  activeLang: string = 'EN';
  private langChangesSubscription: Subscription;
  constructor(private _translocoService: TranslocoService, private title: Title) { }

  ngOnInit(): void {
    this.title.setTitle('Privacy-policy')
    this.langChangesSubscription = this._translocoService.langChanges$.subscribe((activeLang) => {
      this.activeLang = activeLang;
    });
  }

  ngOnDestroy(): void {
    if (this.langChangesSubscription) {
      this.langChangesSubscription.unsubscribe();
    }
  }
}
