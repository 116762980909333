<mat-sidenav-container [hasBackdrop]="true">
  <mat-sidenav
    #sidenavNav
    mode="over"
    opened="false"
    [style.width.px]="300"
    [fixedInViewport]="true"
    fixedTopGap="0"
  >
    <div class="flex flex-wrap p-3 items-center">
      <img
        class="border-none w-24"
        src="assets/home/logo30w.png"
        alt="Zirve Logo"
        loading="lazy"
      />
      <a class="ml-auto" (click)="sidenavNav.close()">
        <i class="fas fa-times"></i>
      </a>
    </div>
    <hr />
    <mat-nav-list>
      <a
        mat-list-item
        class="arabic"
        style="font-size: 14px"
        (click)="sidenavNav.close()"
        [routerLink]="[activeLang, 'home']"
      >
        <span style="margin-top: 5px"> {{ "home" | transloco }}</span>
      </a>

      <hr class="dropdown-divider" />
      <a
        mat-list-item
        class="arabic"
        style="font-size: 14px"
        (click)="sidenavNav.close()"
        [routerLink]="[activeLang, 'equipment_categories']"
      >
        <span style="margin-top: 5px">
          {{ "equipmentCategories" | transloco }}</span
        >
      </a>

      <hr class="dropdown-divider" />
      <a
        mat-list-item
        class="arabic"
        style="font-size: 14px"
        (click)="sidenavNav.close()"
        href="https://zirveextrusion.net/en/home"
        target="_blank"
      >
        <span style="margin-top: 5px">
          {{ "productionLines" | transloco }}</span
        >
      </a>

      <hr class="dropdown-divider" />
      <a
        mat-list-item
        class="arabic"
        style="font-size: 14px"
        (click)="sidenavNav.close()"
        [routerLink]="[activeLang, 'news']"
      >
        <span style="margin-top: 5px"> {{ "news" | transloco }}</span>
      </a>

      <hr class="dropdown-divider" />
      <a
        mat-list-item
        class="arabic"
        style="font-size: 14px"
        (click)="sidenavNav.close()"
        [routerLink]="[activeLang, 'about_company']"
      >
        <span style="margin-top: 5px"> {{ "aboutCompany" | transloco }}</span>
      </a>

      <hr class="dropdown-divider" />
      <a
        mat-list-item
        class="arabic"
        style="font-size: 14px"
        (click)="sidenavNav.close()"
        [routerLink]="[activeLang, 'general_manager']"
      >
        <span style="margin-top: 5px"> {{ "generalManager" | transloco }}</span>
      </a>

      <hr class="dropdown-divider" />
      <a
        mat-list-item
        class="arabic"
        style="font-size: 14px"
        (click)="sidenavNav.close()"
        [routerLink]="[activeLang, 'blogs']"
      >
        <span style="margin-top: 5px"> {{ "blogs" | transloco }}</span>
      </a>

      <hr class="dropdown-divider" />
      <a
        mat-list-item
        class="arabic"
        style="font-size: 14px"
        (click)="sidenavNav.close()"
        [routerLink]="[activeLang, 'contact']"
      >
        <span style="margin-top: 5px"> {{ "contact" | transloco }}</span>
      </a>

      <hr class="dropdown-divider" />

      <div class="bold-line"></div>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-header
      [ngClass]="activeLang === 'AR' ? 'arabic' : 'english'"
    ></app-header>
    <router-outlet></router-outlet>
    <app-footer
      [ngClass]="activeLang === 'AR' ? 'arabic' : 'english'"
    ></app-footer>
    <app-chat [ngClass]="activeLang === 'AR' ? 'arabic' : 'english'"></app-chat>
  </mat-sidenav-content>
</mat-sidenav-container>
<div
  *ngIf="cookiesAcp !== 'accepted'"
  class="fixed bottom-0 w-full h-16 bg-slate-800 z-50"
>
  <div class="flex items-center justify-around p-3">
    <div class="flex text-white">
      <p>
        We use cookies to improve your experience on our website, to show you
        personalized content, and to analyze our website traffic...
      </p>
    </div>
    <div>
      <button
        class="bg-transparent hover:bg-yellow-500 text-white font-semibold py-2 px-4 border border-yellow-500 hover:border-transparent rounded"
        (click)="acceptFun()"
      >
        Accept
      </button>
    </div>
  </div>
</div>
