import { Component, Inject, OnDestroy, PLATFORM_ID, ViewChild } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from './auth/auth.service';
import { isPlatformBrowser } from '@angular/common';
import { SidenavService } from './services/sidenav.service';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  @ViewChild('sidenavNav') public sidenavNav: MatSidenav;
  activeLang: string = 'EN';
  cookiesAcp: string = 'accepted';
  private unsubscribe$ = new Subject<void>();

  constructor(
    private _translocoService: TranslocoService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private sidenavService: SidenavService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {

    if (isPlatformBrowser(this.platformId)) {
      this.cookiesAcp = localStorage.getItem('cookies');
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe$)
      ).subscribe(() => {
        const lang = this.route.snapshot.firstChild?.paramMap.get('lang');
        if (lang && lang !== 'EN') {
          this._translocoService.setActiveLang(lang);
          this.activeLang = lang;
        }
      });

    }
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.sidenavService.setSidenav(this.sidenavNav);
      var token: string = localStorage.getItem('accessToken');
      if (token) {
        this.authService.check().subscribe();
      }
    }

  }

  acceptFun() {
    localStorage.setItem('cookies', 'accepted');
    this.cookiesAcp = 'accepted';
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
