<div class="relative w-full top-0 h-[4.6rem] bg-gray-900"></div>

<div class="w-full px-3 flex justify-center my-5 md:my-20">
  <div
    #bookContainer
    id="book"
    class="w-full xl:w-5/6 h-[10rem] md:h-[20rem] lg:h-[28rem] xl:h-[35rem]"
  >
    <div class="page" *ngFor="let image of images">
      <img [src]="image" loading="lazy" width="100%" />
    </div>
  </div>
  <img class="hidden md:block w-10 h-10" src="assets\contact\swipe-left.gif" />
</div>
<div class="flex justify-end">
  <img class="block md:hidden w-10 h-10" src="assets\contact\swipe-left.gif" />
</div>
