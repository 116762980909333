import { Injectable } from "@angular/core";
import { TranslocoLoader, Translation } from "@ngneat/transloco";
import { HttpClient } from "@angular/common/http";

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    constructor(private http: HttpClient) { }

    // getTranslation(lang: string) {
    //     return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
    // }

    getTranslation(lang: string) {
        const url = `/assets/i18n/${lang}.json`;
        return this.http.get(url);
    }

}
// const url = `https://zirvecompany.net/assets/i18n/${lang}.json`; //production
// const url = `/assets/i18n/${lang}.json`; //Local