import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, Inject, OnDestroy, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ImageCacheService } from "app/services/imageCache.service";
import { environment } from "environments/environment";
import { Subscription } from "rxjs";

@Component({
  selector: 'three-dviewer-dialog',
  templateUrl: './three-dviewer-dialog.html',
  styleUrls: ['./three-dviewer-dialog.scss']
})
export class ThreeDViewerDialogComponent implements OnDestroy {
  imageUrls: any[] = [];
  imageUrlsLength: number;
  currentImagePath: string = '';
  folderName: string = '';
  rouerName: string = '';
  imgWidth: string = '';
  startLoadImages: boolean = false;
  showImageViewer: boolean = false;
  imageLoadingSubscription: Subscription;
  isMouseDown = false;
  mouseDistance = 1;
  @ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLCanvasElement>;
  context: CanvasRenderingContext2D;
  prevX: number;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private bagImagecash: ImageCacheService,
    private http: HttpClient,
    public dialogRef: MatDialogRef<ThreeDViewerDialogComponent>,
  ) {
    this.subscripForImagesLoadingPrograse();
    if (data) {
      this.rouerName = data.routeName;
      this.imgWidth = data.imgWidth;
      console.log(this.imgWidth);

      this.imageUrls = data.images;
      this.folderName = data.folderName;
      this.imageUrlsLength = this.imageUrls.length - 1;

      this.loadAllImages();
      const cachedImage = this.bagImagecash.getImage(this.imageUrls[0]);
      this.currentImagePath = cachedImage || this.imageUrls[0];

    }
  }


  ngAfterViewInit() {
    if (typeof document === 'undefined') {
      return; // Not running in the browser, do nothing
    }
    this.context = this.canvas.nativeElement.getContext('2d');
    this.context.globalAlpha = 0.001; // Set global alpha to make canvas semi-transparent
    this.drawBackground();

  }

  drawBackground() {
    this.context.fillStyle = 'white'; // Set the fill color to white
    this.context.fillRect(0, 0, this.context.canvas.width, this.context.canvas.height); // Draw a filled rectangle covering the canvas
  }

  onMouseDown() {
    this.isMouseDown = true;
  }

  onMouseUp() {
    this.isMouseDown = false;
  }


  loadAllImages() {
    this.bagImagecash.preloadImages(this.imageUrls);
    this.startLoadImages = true;
  }


  onClose(): void {
    this.dialogRef.close();
  }

  subscripForImagesLoadingPrograse() {
    this.imageLoadingSubscription = this.bagImagecash.loadingProgress.subscribe(data => {
      if (data.done) { // Completion signal
        this.showImageViewer = true;
      }
    });
  }


  onMouseMove(event: MouseEvent) {
    if (this.isMouseDown) {
      if (typeof this.prevX !== 'undefined') {
        if (event.clientX > this.prevX) {
          // Mouse is moving to the right
          this.changeIMGIndexByMood('right')
        } else if (event.clientX < this.prevX) {
          // Mouse is moving to the left
          this.changeIMGIndexByMood('left')
        }
      }
      this.prevX = event.clientX;
    }
  }

  onTouchStart(event: TouchEvent) {
    this.isMouseDown = true;
    this.prevX = event.touches[0].clientX;
  }

  onTouchMove(event: TouchEvent) {
    if (this.isMouseDown) {
      if (typeof this.prevX !== 'undefined') {
        const currentX = event.touches[0].clientX;
        if (currentX > this.prevX) {
          // Touch is moving to the right
          this.changeIMGIndexByMood('right');
        } else if (currentX < this.prevX) {
          // Touch is moving to the left
          this.changeIMGIndexByMood('left');
        }
        this.prevX = currentX;
      }
    }
  }

  onTouchEnd() {
    this.isMouseDown = false;
  }

  changeIMGIndexByMood(mood) {
    if (mood === 'left') {
      if (this.mouseDistance <= this.imageUrlsLength) {
        this.mouseDistance += 1
        if (this.mouseDistance === this.imageUrlsLength) {
          this.mouseDistance = 0;
        }
        this.onImgIndexChange(this.mouseDistance);
      }
    } else {
      if (this.mouseDistance >= 0) {
        this.mouseDistance -= 1
        if (this.mouseDistance === 0) {
          this.mouseDistance = this.imageUrlsLength;
        }
        this.onImgIndexChange(this.mouseDistance);
      }
    }

  }


  onImgIndexChange(imgIndex) {
    const newImagePath = `${environment.baseUrl2}images/${this.rouerName}/${this.folderName}/10${imgIndex.toString().padStart(3, '0')}.webp`;
    const cachedImage = this.bagImagecash.getImage(newImagePath);
    if (cachedImage) {
      this.currentImagePath = cachedImage;
    } else {
      this.currentImagePath = newImagePath;
      // Fetch and store in cache
      this.http.get(newImagePath, { responseType: 'blob' })
        .subscribe(imageBlob => {
          // Assuming images are not huge, storing as base64 for simplicity
          const reader = new FileReader();
          reader.readAsDataURL(imageBlob);
          reader.onloadend = () => {
            const base64data = reader.result as string;
            this.bagImagecash.storeImage(newImagePath, base64data);
          };
        });
    }
  }

  ngOnDestroy(): void {
    if (this.imageLoadingSubscription) {
      this.imageLoadingSubscription.unsubscribe();
    }
  }
}
