import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
    transform(value: string, truncateLength: number): string {
        if (value) {
            const words = value.split(' ');
            if (words.length > truncateLength) {
                return words.slice(0, truncateLength).join(' ') + '...';
            } else {
                return value;
            }
        }

    }
}
