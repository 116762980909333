<div class="relative w-full top-0 h-[4.6rem] bg-gray-900"></div>

<div class="container mx-auto p-4">
  <h1 class="text-2xl font-bold mb-4">
    Kişisel Verilerin Korunması Kanunu Aydınlatma Metni
  </h1>

  <p class="font-semibold">
    VERİ SORUMLUSU: Zirve International Makina San. Tic. Ltd. Şti., Sanayi Mah.
    60352 sok. NO: 29 Şehitkamil, Gaziantep, Türkiye
  </p>

  <p class="mt-4">
    Zirve International Makina San. Tic. Ltd. Şti. (Veri Sorumlusu) olarak
    aşağıdaki kişisel verileriniz işlenmekte, saklanmakta ve korunmaktadır:
  </p>

  <ul class="list-disc ml-6 mt-4">
    <li>
      <span class="font-semibold">Kimlik Bilgileriniz:</span> Adınız soyadınız,
      anne-baba adınız, doğum tarihiniz, doğum yeriniz, cinsiyetiniz, uyruğunuz,
      TC kimlik numaranız, kimlik bilgileriniz, varsa kimlik fotokopiniz
      suretiyle elde edilen kan grubu ve din hanesi bilgisi, imza yetkilisi olup
      olmadığınız bilgisi, fotoğrafınız.
    </li>
    <li>
      <span class="font-semibold">İletişim Bilgileriniz:</span> Adresiniz,
      telefon numaranız, e-posta adresiniz, varsa KEP adresiniz.
    </li>
    <li>
      <span class="font-semibold">Müşteri Bilgileriniz:</span> Müşteri işlem
      bilgileriniz, imzanız, çalıştığınız şirket bilgileri, vergi bilgileriniz,
      banka hesabınıza ilişkin bilgiler, mesleki deneyim bilgileriniz, meslek,
      görev ve unvan bilgileriniz, araç bilgileriniz, hukuki işlemler kapsamında
      elde edilen bilgileriniz, vergi numaranız, talimat, talep ve
      şikayetlerinize ilişkin bilgiler, ürün ve hizmetlerimize ilişkin tercih ve
      alışkanlıklarınız.
    </li>
    <li>
      <span class="font-semibold">İşlem Güvenliği Bilgileriniz:</span> MAC
      adresiniz, log kayıtlarınız.
    </li>
    <li>
      <span class="font-semibold">Görsel ve İşitsel Bilgileriniz:</span>
      Fotoğraflarınız ve kamera kayıtlarınız.
    </li>
  </ul>

  <p class="mt-4">
    Bu nedenle, 6698 sayılı Kişisel Verilerin Korunması Kanunu (Kanun)
    kapsamında Veri Sorumlusu sıfatıyla sizleri aydınlatmak isteriz.
  </p>

  <h2 class="text-lg font-semibold mt-6">
    1. Kişisel Verileri Toplama Yöntemi ve Hukuki Sebebi
  </h2>
  <p>
    Kişisel verileriniz; bu aydınlatma metninde belirtilen amaç ve kapsamda,
    fiziki ve elektronik ortamlarda toplanan "kimliği belirli veya
    belirlenebilir gerçek kişiye ilişkin her türlü bilgiyi" ifade etmektedir.
    Kişisel verileriniz, Kanun’un 5. Maddesinde belirtilen "kanunlarda açıkça
    öngörülmesi," "bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya
    ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin
    işlenmesinin gerekli olması," "kişisel veri işlemenin veri sorumlusunun
    hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması," "kişisel
    veri işlemenin bir hakkın tesisi, kullanılması veya korunması için veri
    işlemenin zorunlu olması" ve "ilgili kişinin temel hak ve özgürlüklerine
    zarar vermemek kaydıyla, veri sorumlusunun meşru menfaatleri için veri
    işlenmesinin zorunlu olması" hukuki sebeplerine dayalı olarak işlenmektedir.
    Belirtilen hukuki sebeplerden en az birisinin bulunmaması halinde, kişisel
    veri işlemeye ilişkin açık rızanız sorulacaktır. Açık rızanızın sorulduğu
    halleri "Açık Rıza Formunda" bulabilirsiniz.
  </p>

  <h2 class="text-lg font-semibold mt-6">
    2. Kişisel Verilerin İşlenme Amaçları
  </h2>
  <p>Toplanan kişisel verileriniz aşağıdaki amaçlarla işlenecektir:</p>

  <ul class="list-disc ml-6 mt-4">
    <li>Acil durum yönetimi süreçlerinin yürütülmesi,</li>
    <li>Bilgi güvenliği süreçlerinin yürütülmesi,</li>
    <li>Denetim/etik faaliyetlerinin yürütülmesi,</li>
    <li>Erişim yetkilerinin yürütülmesi,</li>
    <li>Faaliyetlerin mevzuata uygun yürütülmesi,</li>
    <li>Finans ve muhasebe işlerinin yürütülmesi,</li>
    <li>Firma/ürün/hizmetlere bağlılık süreçlerinin yürütülmesi,</li>
    <li>Fiziksel mekan güvenliğinin temini,</li>
    <li>Görevlendirme süreçlerinin yürütülmesi,</li>
    <li>Hukuk işlerinin takibi ve yürütülmesi,</li>
    <li>İç denetim/soruşturma/istihbarat faaliyetlerinin yürütülmesi,</li>
    <li>İletişim faaliyetlerinin yürütülmesi,</li>
    <li>İnsan kaynakları süreçlerinin planlanması,</li>
    <li>İş faaliyetlerinin yürütülmesi/denetimi,</li>
    <li>İş sağlığı/güvenliği faaliyetlerinin yürütülmesi,</li>
    <li>
      İş süreçlerinin iyileştirilmesine yönelik önerilerin alınması ve
      değerlendirilmesi,
    </li>
    <li>İş sürekliliğinin sağlanması faaliyetlerinin yürütülmesi,</li>
    <li>Lojistik faaliyetlerinin yürütülmesi,</li>
    <li>Mal/hizmet satın alım süreçlerinin yürütülmesi,</li>
    <li>Mal/hizmet satış sonrası destek hizmetlerinin yürütülmesi,</li>
    <li>Mal/hizmet satış süreçlerinin yürütülmesi,</li>
    <li>Mal/hizmet üretim ve operasyon süreçlerinin yürütülmesi,</li>
    <li>Müşteri ilişkileri yönetimi süreçlerinin yürütülmesi,</li>
    <li>Müşteri memnuniyetine yönelik aktivitelerin yürütülmesi,</li>
    <li>Organizasyon ve etkinlik yönetimi,</li>
    <li>Reklam/kampanya/promosyon süreçlerinin yürütülmesi,</li>
    <li>Risk yönetimi süreçlerinin yürütülmesi,</li>
    <li>Saklama ve arşiv faaliyetlerinin yürütülmesi,</li>
    <li>Sosyal sorumluluk ve sivil toplum aktivitelerinin yürütülmesi,</li>
    <li>Sözleşme süreçlerinin yürütülmesi,</li>
    <li>Stratejik planlama faaliyetlerinin yürütülmesi,</li>
    <li>Talep/şikayetlerin takibi,</li>
    <li>Taşınır mal ve kaynakların güvenliği süreçlerinin yürütülmesi,</li>
    <li>Tedarik zinciri yönetimi süreçlerinin yürütülmesi,</li>
    <li>Telekomünikasyon hizmetlerinin yürütülmesi,</li>
    <li>Ürün/hizmet satış ve pazarlama süreçlerinin yürütülmesi,</li>
    <li>Veri yönetimi süreçlerinin yürütülmesi,</li>
    <li>Yasal yükümlülüklerin yerine getirilmesi,</li>
    <li>Yatırımcı ilişkileri süreçlerinin yürütülmesi,</li>
    <li>Yönetim süreçlerinin yürütülmesi.</li>
  </ul>

  <h2 class="text-lg font-semibold mt-6">3. Kişisel Verilerin Aktarılması</h2>
  <p>
    Kişisel verileriniz, yukarıda belirtilen amaçlar doğrultusunda iş
    ortaklarımıza, tedarikçilerimize, kanuni zorunluluklar doğrultusunda ilgili
    kamu kurum ve kuruluşlarına, yurt dışında bulunan veri işleyenlerine,
    müşterilerimize ve diğer üçüncü kişilere aktarılabilecektir.
  </p>

  <h2 class="text-lg font-semibold mt-6">4. Kişisel Veri Sahibinin Hakları</h2>
  <p>
    6698 sayılı Kanun’un 11. maddesi uyarınca, kişisel veri sahipleri olarak
    sahip olduğunuz haklar;
  </p>

  <ul class="list-disc ml-6 mt-4">
    <li>Kişisel veri işlenip işlenmediğini öğrenme,</li>
    <li>Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,</li>
    <li>
      Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp
      kullanılmadığını öğrenme,
    </li>
    <li>
      Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü
      kişileri bilme,
    </li>
    <li>
      Kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde bunların
      düzeltilmesini isteme,
    </li>
    <li>
      6698 sayılı Kanun ve ilgili mevzuat uyarınca kişisel verilerinizin
      silinmesini veya yok edilmesini isteme,
    </li>
    <li>
      İşlenen verilerinize ilişkin olarak yapılan işlemlerin, kişisel verilerin
      aktarıldığı üçüncü kişilere bildirilmesini isteme,
    </li>
    <li>
      Kişisel verilerinizin münhasıran otomatik sistemler vasıtasıyla analiz
      edilmesi nedeniyle aleyhinize bir sonucun ortaya çıkmasına itiraz etme,
    </li>
    <li>
      Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara
      uğramanız hâlinde zararın giderilmesini talep etme hakkına sahipsiniz.
    </li>
  </ul>

  <p>
    Bu haklarınıza yönelik taleplerinizi, Veri Sorumlusu olarak Zirve
    International Makina San. Tic. Ltd. Şti.’ye kimliğinizi tespit edici
    belgeler ile başvuruda bulunarak kullanabilirsiniz.
  </p>

  <h2 class="text-lg font-semibold mt-6">
    5. Veri Sorumlusuna Başvuru Yolları
  </h2>
  <p>
    Veri sorumlusu olarak Zirve International Makina San. Tic. Ltd. Şti.’ye
    başvuru yollarını aşağıda belirtiyoruz:
  </p>

  <ul class="list-disc ml-6 mt-4">
    <li>
      Sanayi Mah. 60352 sok. NO: 29 Şehitkamil, Gaziantep, Türkiye adresindeki
      merkezimize şahsen başvuruda bulunabilirsiniz.
    </li>
    <li>
      info@zirve.international e-posta adresine kimliğinizi tespit edici
      belgelerle güvenli elektronik imza ile başvurabilirsiniz.
    </li>
  </ul>

  <p class="mt-4">
    Zirve International Makina San. Tic. Ltd. Şti. olarak, Kişisel Verilerin
    Korunması Kanunu kapsamında veri güvenliğinizi ön planda tutmak ve yasal
    mevzuata uygun şekilde kişisel verilerinizi işlemek için gerekli tüm teknik
    ve idari önlemleri almaktayız.
  </p>

  <p class="mt-4">
    Bu Aydınlatma Metni, 6698 sayılı Kişisel Verilerin Korunması Kanunu ve
    ilgili mevzuat kapsamında hazırlanmış olup, güncellenmesi durumunda
    tarafınıza bildirilecektir.
  </p>
</div>
