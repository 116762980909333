import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';

// declare var $: any;

declare var $: any;

@Component({
  selector: 'app-catalogs',
  templateUrl: './catalogs.component.html',
  styleUrls: ['./catalogs.component.scss']
})
export class CatalogsComponent implements AfterViewInit {
  images: any[] = [];
  @ViewChild('bookContainer') bookContainer: ElementRef;
  constructor(@Inject(PLATFORM_ID) private platformId: object, private renderer: Renderer2) {
    if (isPlatformBrowser(this.platformId)) {
      for (let index = 1; index < 61; index++) {
        this.images.push(`https://msr.zirvecompany.net/images/packagingCatalog/${index}.webp`)
      }
    }

  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.loadScript('https://ajax.googleapis.com/ajax/libs/jquery/3.6.3/jquery.min.js').then(() => {
        this.loadScript('assets/js/turn.js').then(() => {
          if (isPlatformBrowser(this.platformId)) {
            $(this.bookContainer.nativeElement).turn({
              autoCenter: true,
              acceleration: true
            });
          }

        })

      });
    }
  }

  private loadScript(src: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const script = this.renderer.createElement('script');
      script.src = src;
      script.onload = resolve;
      script.onerror = reject;
      this.renderer.appendChild(document.head, script);
    });
  }

}
