import { Component, HostListener, OnInit, Inject, ViewChild, ElementRef, } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
// import { baseURL } from '../shared/baseurl';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  products: any[] = [];
  typedText: string = '';
  timeout: any = null;
  activeLang!: string;
  @ViewChild('searchID') searchIDVariable!: ElementRef<any>;

  @HostListener('document:keypress', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === 13 && this.typedText.length > 2) {
      localStorage.setItem('machines', JSON.stringify(this.products));
      // this.products = [];
      this.searchIDVariable.nativeElement.value = ""
      this.router.navigate([this.activeLang, 'search_machines']);
    }
  }

  constructor(
    private _httpClient: HttpClient,
    public router: Router,
    private _translocoService: TranslocoService,
    // @Inject('BaseURL') public BaseURL: string
  ) { }

  ngOnInit(): void {
    this._translocoService.langChanges$.subscribe((activeLang) => {
      this.activeLang = activeLang;
    });
  }

  executeListing(value: string) {
    if (value.length > 2) {
      let keyy = value.toLocaleLowerCase();
      this.products = [];
      this._httpClient.get(`${environment.baseUrl2}productForm/searchBy/${keyy}`)
        .subscribe((resultSets: any) => {
          this.products = resultSets;
        });
    } else {
      this.products = []
    }

  }

  onKeySearch(event: any) {
    clearTimeout(this.timeout);
    const $this = this;
    if (event.keyCode != 13) {
      this.timeout = setTimeout(() => {
        $this.executeListing(event.target.value);
        this.typedText = event.target.value;
      }, 600);
    }
  }

  resetArray() {
    setTimeout(() => {
      this.products = [];
      this.searchIDVariable.nativeElement.value = "";
    }, 1000);

  }
}
