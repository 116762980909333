<div class="relative w-full top-0 h-[4.6rem] bg-gray-900"></div>
<div [ngClass]="activeLang === 'AR' ? 'arabic' : 'english'">
  <div class="flex items-center justify-center mt-10 mb-14">
    <p
      class="text-center inline-block text-lg lg:text-3xl border border-black px-5 py-1 rounded-full"
    >
      {{ equipment?.title[activeLang] }}
    </p>
  </div>
  <div class="px-1 lg:px-10 grid grid-cols-1 xl:grid-cols-2 items-center gap-5">
    <div class="flex mb-10" *ngFor="let item of machines$ | async">
      <div class="w-1/2">
        <a [routerLink]="['/', activeLang, 'machine', item.seoURL]">
          <img
            *ngIf="item.images[0].url !== 'none'"
            class="mx-auto"
            [src]="item.images[0]?.url"
            loading="lazy"
            width="80%"
            [alt]="item.altText"
          />
        </a>
      </div>
      <div class="w-1/2">
        <a
          class="hover:text-[#E60027]"
          [routerLink]="['/', activeLang, 'machine', item.seoURL]"
        >
          <h1 class="mt-2 fontBold">
            {{ item.name[activeLang] }}
          </h1>
        </a>
        <p *ngIf="item.smallDes" [dir]="activeLang === 'AR' ? 'rtl' : 'ltr'">
          {{ item.smallDes[activeLang] }}
        </p>
      </div>
    </div>
  </div>
</div>

<div class="my-60"></div>
