import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { GeneralService } from 'app/services/general.service';
import { HreflangService } from 'app/services/hreflang.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit, AfterViewInit, OnDestroy {
  activeLang: string = 'EN';
  firstRun: boolean;
  countries: any[] = [];
  public L = null;
  private map;

  private langChangesSubscription: Subscription;

  constructor(
    private _translocoService: TranslocoService,
    private meta: Meta,
    private hreflangService: HreflangService,
    private gs: GeneralService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private renderer: Renderer2) {
    if (isPlatformBrowser(platformId)) {
      this.L = require('leaflet');
    }
  }

  ngOnInit(): void {
    if (typeof document === 'undefined') {
      return; // Not running in the browser, do nothing
    }
    const link = this.renderer.createElement('link');
    this.renderer.setAttribute(link, 'rel', 'stylesheet');
    this.renderer.setAttribute(link, 'href', 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/leaflet.css');
    this.renderer.appendChild(document.head, link);
    this.getCountries();
    this.getActiveLang();
  }

  getCountries() {
    this.gs.getItems('country').subscribe((countriesRes) => {
      this.countries = countriesRes;
      this.addFlags();

    })
  }

  getActiveLang() {
    this.langChangesSubscription = this._translocoService.langChanges$.subscribe((activeLang) => {
      if (this.activeLang !== activeLang || !this.firstRun) {
        this.deleteSchemaMeta();
        setTimeout(() => {
          this.hreflangService.updateHreflangTags(activeLang, `about_company`);
        }, 1000);
        this.firstRun = true;
      }

      this.activeLang = activeLang;
    });
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.initMap();
    }

  }
  private initMap(): void {
    this.map = this.L.map('map', {
      attributionControl: false,
      center: [39.93, 32.87],
      zoom: 2,
      maxBounds: [[-90, -180], [90, 180]] // Set the maximum bounds to prevent horizontal dragging
    });

    const tiles = this.L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 3,
      minZoom: 2,
    });

    tiles.addTo(this.map);
  }

  addFlags() {
    this.countries.forEach((el) => {
      var flagIcon = this.L.icon({
        iconUrl: 'assets/aboutUS/flag-zırveS.png',
        iconSize: [16, 22],
      });

      this.L.marker([el.latitude, el.longitude], { icon: flagIcon }).addTo(this.map);
    })

  }

  ngOnDestroy() {
    if (this.langChangesSubscription) {
      this.langChangesSubscription.unsubscribe();
    }

    // Delete Robots
    const robotTags = this.meta.getTags('name="robots"');
    if (robotTags.length > 0) {
      robotTags.forEach(tag => {
        try {
          this.meta.removeTagElement(tag);
        } catch (error) {
          console.error('Error removing robot tag:', error);
        }
      });
    }
    this.deleteSchemaMeta();
  }

  deleteSchemaMeta() {
    // Delete Hreflang
    const hreflangTags = this.meta.getTags('name="hreflang"');
    if (hreflangTags.length > 0) {
      hreflangTags.forEach(tag => {
        try {
          this.meta.removeTagElement(tag);
        } catch (error) {
          console.error('Error removing hreflang tag:', error);
        }
      });
    }

    // Delete Canonical
    try {
      this.meta.removeTag('rel=canonical');
    } catch (error) {
      console.error('Error removing canonical tag:', error);
    }
  }
}
