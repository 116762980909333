<div class="relative w-full top-0 h-[4.6rem] bg-gray-900"></div>
<div
  class="bgColor py-10 w-full flex items-center justify-center"
  [ngClass]="activeLang === 'AR' ? 'arabic' : 'english'"
>
  <div class="bg-white w-11/12 lg:w-2/3 xl:w-1/3 rounded-xl shadow-lg p-6">
    <p class="text-center mainColor text-3xl font-extrabold pb-5">
      {{ "signUp" | transloco }}
    </p>
    <p *ngIf="errMesege" class="text-center text-red-600">
      {{ errMesege }}
    </p>

    <form [formGroup]="signupForm" (ngSubmit)="onSubmit()" autocomplete="off">
      <mat-form-field appearance="outline">
        <mat-label> {{ "name" | transloco }}</mat-label>
        <input
          matInput
          formControlName="name"
          [placeholder]="'enterYourName' | transloco"
          type="text"
        />
        <mat-error *ngIf="formErrors.name">{{ formErrors.name }}</mat-error>
        <span class="icon-container">
          <mat-icon>text_fields</mat-icon>
        </span>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label> {{ "lastName" | transloco }}</mat-label>
        <input
          matInput
          formControlName="lastname"
          [placeholder]="'enterYourLastName' | transloco"
          type="text"
        />
        <mat-error *ngIf="formErrors.lastName">{{
          formErrors.lastName
        }}</mat-error>
        <span class="icon-container">
          <mat-icon>text_fields</mat-icon>
        </span>
      </mat-form-field>

      <mat-form-field class="mt-4" appearance="outline">
        <mat-label> {{ "email" | transloco }}</mat-label>
        <input
          matInput
          formControlName="email"
          [placeholder]="'enterYourEmail' | transloco"
          type="email"
        />
        <mat-error *ngIf="formErrors.email">{{ formErrors.email }}</mat-error>
        <span class="icon-container">
          <mat-icon>email</mat-icon>
        </span>
      </mat-form-field>
      <mat-form-field class="mt-4" appearance="outline">
        <mat-label> {{ "password" | transloco }} </mat-label>
        <input
          matInput
          formControlName="password"
          [placeholder]="'enterYourEmailpass' | transloco"
          type="password"
        />
        <mat-error *ngIf="formErrors.password">{{
          formErrors.password
        }}</mat-error>
        <span class="icon-container">
          <mat-icon>lock</mat-icon>
        </span>
      </mat-form-field>
      <mat-form-field class="mt-4" appearance="outline">
        <mat-label> {{ "confirmPassword" | transloco }} </mat-label>
        <input
          matInput
          formControlName="confirmPassword"
          [placeholder]="'confirmPassword' | transloco"
          type="password"
        />

        <mat-error
          *ngIf="
            signupForm.errors?.notEquivalent &&
            signupForm.get('confirmPassword').touched
          "
          >Passwords Match</mat-error
        >

        <span class="icon-container">
          <mat-icon>lock</mat-icon>
        </span>
      </mat-form-field>

      <button
        type="submit"
        [disabled]="loading"
        class="rounded-full mt-5 center w-full bg-[#E60027] px-2.5 py-1.5 text-lg text-white shadow-sm hover:bg-[#D40024] disabled:bg-[#ff6780]"
      >
        <span *ngIf="loading"> {{ "creatingAccount" | transloco }} </span>
        <span *ngIf="!loading"> {{ "signUp" | transloco }} </span>
      </button>
    </form>

    <p class="text-center text-lg pt-5">
      {{ "alreadyMember" | transloco }}
      <a
        [routerLink]="['/', activeLang, 'sign_in']"
        class="font-extrabold mainColor"
      >
        {{ "login" | transloco }}</a
      >
    </p>
  </div>
</div>
