<div class="relative w-full top-0 h-[4.6rem] bg-gray-900"></div>
<div [ngClass]="activeLang === 'AR' ? 'arabic' : 'english'">
  <div class="flex items-center justify-center mt-5">
    <p
      class="text-center inline-block text-xl border border-black px-5 py-1 rounded-full"
    >
      {{ "insightsNews" | transloco }}
    </p>
  </div>
  <div
    class="px-5 lg:px-10 xl:px-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 items-center gap-5 lg:gap-10 xl:gap-14 mt-10"
  >
    <div
      class="shadow-lg rounded-t-xl h-full"
      *ngFor="let item of news$ | async"
      [dir]="activeLang === 'AR' ? 'rtl' : 'ltr'"
    >
      <div class="w-full h-64 mx-auto overflow-hidden">
        <img
          *ngIf="item.image.url !== 'none'"
          [src]="item.image.url"
          loading="lazy"
          alt="Image 1"
          class="center object-cover w-full h-full"
        />
      </div>
      <div class="flex items-center justify-between p-2 text-[#5B5B5B]">
        <span> {{ item.date | date : "M/d/yyy" }} </span>
      </div>
      <div class="p-2">
        <a
          class="hover:text-[#E60027] cursor-pointer"
          [routerLink]="['/', activeLang, 'news', item._id]"
        >
          <p class="font-bold">
            {{ item.title[activeLang] }}
          </p>
          <div
            class="flex items-center hover:text-[#E60027] cursor-pointer"
            dir="ltr"
          >
            {{ "learnMore" | transloco }}
            <svg
              class="ml-3"
              width="15"
              height="16"
              viewBox="0 0 27 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26.7071 8.70711C27.0976 8.31658 27.0976 7.68342 26.7071 7.29289L20.3431 0.928932C19.9526 0.538408 19.3195 0.538408 18.9289 0.928932C18.5384 1.31946 18.5384 1.95262 18.9289 2.34315L24.5858 8L18.9289 13.6569C18.5384 14.0474 18.5384 14.6805 18.9289 15.0711C19.3195 15.4616 19.9526 15.4616 20.3431 15.0711L26.7071 8.70711ZM0 9H26V7H0V9Z"
                stroke="#E60027"
              />
            </svg>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>

<div class="my-60"></div>
