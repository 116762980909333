import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
// import { MetaTag } from 'app/shared/meta_tag';
import { Observable, of, tap } from 'rxjs';
import { MetaTag } from 'app/shared/mate_tag';

@Injectable({
    providedIn: 'root'
})
export class MetaTagService {
    private metaTags: MetaTag[] = [];

    constructor(private http: HttpClient) { }

    getMetaTags(): Observable<MetaTag[]> {
        if (this.metaTags.length > 0) {
            return of(this.metaTags);
        } else {
            return this.http.get<MetaTag[]>(environment.baseUrl2 + `metaTag`).pipe(
                tap(metaTags => {
                    this.metaTags = metaTags;
                })
            );
        }
    }
}
