import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { BlogClass } from 'app/shared/blogs';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-by-bag-product',
  templateUrl: './by-bag-product.component.html',
  styleUrls: ['./by-bag-product.component.scss']
})

export class ByBagProductComponent implements OnInit, OnDestroy {
  machines$: Observable<BlogClass[]>;
  activeLang: string = 'EN';
  private langChangesSubscription: Subscription;

  constructor(public router: ActivatedRoute, private _translocoService: TranslocoService) { }

  ngOnInit(): void {
    this.machines$ = this.router.data.pipe(map((data: any) => data.searchMachines));
    this.getActiveLang();
  }

  getActiveLang() {
    this.langChangesSubscription = this._translocoService.langChanges$.subscribe((activeLang) => {
      this.activeLang = activeLang;
    });
  }

  ngOnDestroy() {
    if (this.langChangesSubscription) {
      this.langChangesSubscription.unsubscribe();
    }
  }
}
