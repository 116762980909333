import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, HostListener } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { MetaTag } from 'app/shared/mate_tag';
import { NewsClass } from 'app/shared/news';
import { isPlatformBrowser } from '@angular/common';
import { BlogClass } from 'app/shared/blogs';
import { Subscription } from 'rxjs';
import { GeneralService } from 'app/services/general.service';
import { UserService } from 'app/user/user.service';
import { HreflangService } from 'app/services/hreflang.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  metaTag: MetaTag;
  user: any = undefined;
  numbers: { value: number, animatedValue: number, increment: number }[] = [
    { value: 3500, animatedValue: 0, increment: 0 },
    { value: 1200, animatedValue: 0, increment: 0 },
    { value: 900, animatedValue: 0, increment: 0 },
    { value: 70, animatedValue: 0, increment: 0 }
  ];
  news: NewsClass[];
  blogs: BlogClass[];
  activeLang: string = 'EN';
  clients: any[] = [];
  partners: any[] = [];
  screenWidth: number = 0;
  firstRun: boolean;
  isCounterRuned: boolean = false;
  browserMood: boolean = false;
  private langChangesSubscription: Subscription;
  breakpoints = {
    300: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 3,
    },
    1024: {
      slidesPerView: 5,
    },
  };

  constructor(
    private _translocoService: TranslocoService,
    private _userService: UserService,
    private hreflangService: HreflangService,
    @Inject(PLATFORM_ID) private platformId: object,
    private meta: Meta,
    private generalService: GeneralService,
  ) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.getActiveLang();
      this.getAllHomePageData();
      this._userService.user$.subscribe((res) => {
        this.user = res;
      })
      this.screenWidth = window.innerWidth;
      // this.getVideoURL();
      this.browserMood = true;
    }
  }

  getVideoURL() {
    if (this.screenWidth <= 768) {
      return 'https://msr.zirvecompany.net/images/smalMain.mp4';
    } else {
      return 'https://msr.zirvecompany.net/images/main.mp4';
    }
  }

  view

  @HostListener('window:scroll', ['$event'])
  onscroll() {
    if (this.isCounterRuned) {
      return;
    }

    if (window.scrollY >= 600) {
      this.animateNumbers();
      this.isCounterRuned = true;
    }
  }

  animateNumbers() {
    const totalDuration = 5000; // Total animation duration in milliseconds
    const steps = 100; // Number of animation steps
    const intervalDuration = totalDuration / steps;
    // Calculate the increment for each counter proportionally
    this.numbers.forEach(number => {
      number.increment = number.value / steps;
    });

    let currentStep = 0;
    const interval = setInterval(() => {
      currentStep++;
      if (currentStep > steps) {
        clearInterval(interval);
        // Ensure the final values are exactly the target values
        this.numbers.forEach(number => {
          number.animatedValue = number.value;
        });
        return;
      }

      this.numbers.forEach(number => {
        const remaining = number.value - number.animatedValue;
        if (currentStep === steps) {
          number.animatedValue += remaining;
        } else {
          number.animatedValue = Math.min(number.value, number.animatedValue + number.increment);
        }
      });
    }, intervalDuration);
  }

  getAllHomePageData() {
    this.generalService.getItems('shared/home').subscribe((res) => {
      let clients = res.client?.documents ?? [];
      this.partners = res.partner?.documents ?? [];
      this.blogs = res.blogs;
      this.news = res.news;
      setTimeout(() => {
        this.prepareClientsLogos(clients);
      }, 1000);
    })
  }

  prepareClientsLogos(clients) {
    if (this.screenWidth >= 1300) {
      clients.forEach((el) => {
        if (el.original_name.slice(0, 4) === 'larg') {
          this.clients.push(el);
        }
      })
    } else if (this.screenWidth >= 700) {
      clients.forEach((el) => {
        if (el.original_name.slice(0, 4) === 'medi') {
          this.clients.push(el);
        }
      })
    } else if (this.screenWidth >= 250) {
      clients.forEach((el) => {
        if (el.original_name.slice(0, 4) === 'smal') {
          this.clients.push(el);
        }
      })
    }
    setTimeout(() => {
      this.clients.sort((a, b) => {
        const numA = parseInt(a.original_name.match(/\d+/)[0], 10);
        const numB = parseInt(b.original_name.match(/\d+/)[0], 10);
        return numA - numB;
      });
    }, 1000);
  }

  getActiveLang() {
    this.langChangesSubscription = this._translocoService.langChanges$.subscribe((activeLang) => {
      if (this.activeLang !== activeLang || !this.firstRun) {
        this.deleteSchemaMeta();
        setTimeout(() => {
          this.hreflangService.updateHreflangTags(activeLang, 'home');
        }, 1000);
        this.firstRun = true;
      }

      this.activeLang = activeLang;
    });
  }


  getArray(length: number): any[] {
    return new Array(length);
  }

  ngOnDestroy() {
    if (this.langChangesSubscription) {
      this.langChangesSubscription.unsubscribe();
    }
    // Delete Robots
    const robotTags = this.meta.getTags('name="robots"');
    if (robotTags.length > 0) {
      robotTags.forEach(tag => {
        try {
          this.meta.removeTagElement(tag);
        } catch (error) {
          console.error('Error removing robot tag:', error);
        }
      });
    }

    this.deleteSchemaMeta();
  }

  deleteSchemaMeta() {
    // Delete Hreflang
    const hreflangTags = this.meta.getTags('name="hreflang"');
    if (hreflangTags.length > 0) {
      hreflangTags.forEach(tag => {
        try {
          this.meta.removeTagElement(tag);
        } catch (error) {
          console.error('Error removing hreflang tag:', error);
        }
      });
    }

    // Delete Canonical
    try {
      this.meta.removeTag('rel=canonical');
    } catch (error) {
      console.error('Error removing canonical tag:', error);
    }
  }

}







