import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'app/auth/auth.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit, OnDestroy {
  activeLang: string = 'EN';
  loading: boolean = false;
  errMesege: string;
  private langChangesSubscription: Subscription;
  loginForm: FormGroup;
  formErrors = {
    email: '',
    password: '',
  };
  validationMessages = {
    email: {
      required: 'Email is required.',
      email: 'Please enter a valid email address.'
    },
    password: {
      required: 'Password is required.'
    },
  };

  constructor(
    private _translocoService: TranslocoService,
    private fb: FormBuilder,
    private _authService: AuthService,
    public router: Router,) { }

  ngOnInit(): void {
    this.createForm();
    this.getActiveLang();

  }

  getActiveLang() {
    this.langChangesSubscription = this._translocoService.langChanges$.subscribe((activeLang) => {
      this.activeLang = activeLang;
    });
  }

  createForm() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
    this.loginForm.valueChanges.subscribe(data => this.onValueChanged(data));
    this.onValueChanged(); // Reset validation messages now
  }


  onValueChanged(data?: any) {
    if (!this.loginForm) {
      return;
    }
    const form = this.loginForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  onSubmit(): void {
    this.loading = true;
    if (this.loginForm.invalid) {
      this.loading = false;
      return;
    }

    this.loginForm.disable();
    this._authService.signIn(this.loginForm.value).subscribe(
      () => {
        this.router.navigate([this.activeLang + "/home"]);
      },
      (response) => {
        this.loading = false;
        this.loginForm.enable();
        if (response === 'User is already logged in') {
          this.errMesege = "User is already logged in";
        }
        if (response?.error?.err) {
          if (response.error.err == "User is not verified.") {
            this.errMesege = "Waiting Admin Acception";
          } else if (response.error.err.name == "IncorrectUsernameError") {
            this.errMesege = "Incorrect email";
          } else if (response.error.err.name == "IncorrectPasswordError") {
            this.errMesege = "Incorrect password";
          }
        } else {
          this.errMesege = "Error occurred while logging in!";
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.langChangesSubscription) {
      this.langChangesSubscription.unsubscribe();
    }
  }

}
