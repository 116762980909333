import {
  provideTransloco,
  TranslocoModule
} from '@ngneat/transloco';
import { NgModule } from '@angular/core';
import { TranslocoHttpLoader } from './transloco-loader';


@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: {
        availableLangs: [
          {
            id: 'EN',
            label: 'English'
          },
          {
            id: 'AR',
            label: 'العربية'
          },
          {
            id: 'TR',
            label: 'Türkçe'
          },
          {
            id: 'FR',
            label: 'Français'
          },
          {
            id: 'RU',
            label: 'Русский'
          }
        ],
        defaultLang: 'EN',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: true, // Set to true in production
        fallbackLang: 'EN' // Language to use if translation is missing
      },
      loader: TranslocoHttpLoader
    }),
  ],
})
export class TranslocoRootModule { }









// {
//   provide: TRANSLOCO_CONFIG,
//   useValue: translocoConfig({
//     availableLangs: ['EN', 'TR'], // Replace with your supported languages
//     defaultLang: 'EN',
//     reRenderOnLangChange: true,
//     prodMode: environment.production, // Set to true in production
//     fallbackLang: 'EN' // Language to use if translation is missing
//   })
// },
// { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader }