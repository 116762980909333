<div class="relative w-full top-0 h-[4.6rem] bg-gray-900"></div>
<div [ngClass]="activeLang === 'AR' ? 'arabic' : 'english'">
  <div class="flex items-center justify-center mt-10 mb-14">
    <p
      class="text-center inline-block text-3xl border border-black px-5 py-1 rounded-full"
    >
      {{ "equipmentCategories" | transloco }}
    </p>
  </div>

  <div
    class="px-5 lg:px-10 xl:px-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 items-center gap-5 lg:gap-10 xl:gap-14"
  >
    <div
      class="shadow-md rounded-lg h-full bg-[#dedede17]"
      *ngFor="let item of equipment"
    >
      <a [routerLink]="['/', activeLang, 'machines', item._id]">
        <img
          class="min-h-[270px] mx-auto"
          *ngIf="item.image.url !== 'none'"
          loading="lazy"
          [src]="item.image?.url"
          width="80%"
          [alt]="item.altText"
        />
        <div class="p-2">
          <p class="font-semibold text-center mt-2 text-[#E60027]">
            {{ item.title[activeLang] }}
          </p>
        </div>
      </a>
    </div>
  </div>
</div>

<div class="my-60"></div>
