import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { GeneralService } from 'app/services/general.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  date = new Date();
  userEmail: string = '';
  activeLang: string = 'EN';
  constructor(private gs: GeneralService, private _translocoService: TranslocoService) {
  }

  ngOnInit(): void {
    if (typeof document === 'undefined') {
      return; // Not running in the browser, do nothing
    }
    this.getActiveLang();
  }

  getActiveLang() {
    this._translocoService.langChanges$.subscribe((activeLang) => {
      this.activeLang = activeLang;

    });
  }
  onSubmit() {
    if (this.userEmail !== '' && this.userEmail !== ' ') {
      this.gs.addItem('subscripEmail', { email: this.userEmail }).subscribe(() => {
        this.userEmail = ''
      })
    }
  }
}
