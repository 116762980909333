import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Machine } from 'app/shared/machine';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search-machines',
  templateUrl: './search-machines.component.html',
  styleUrls: ['./search-machines.component.scss']
})
export class SearchMachinesComponent implements OnInit, OnDestroy {
  machines: Machine[] = [];
  activeLang: string = 'EN';
  private langChangesSubscription: Subscription;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private _translocoService: TranslocoService,) { }


  ngOnInit(): void {
    this.getActiveLang();
    if (isPlatformBrowser(this.platformId)) {
      this.machines = JSON.parse(localStorage.getItem('machines'));
    }
  }


  getActiveLang() {
    this.langChangesSubscription = this._translocoService.langChanges$.subscribe((activeLang) => {
      this.activeLang = activeLang;
    });
  }

  ngOnDestroy() {
    if (this.langChangesSubscription) {
      this.langChangesSubscription.unsubscribe();
    }
  }
}
