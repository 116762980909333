import { Injectable, makeStateKey } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { GeneralService } from 'app/services/general.service';
import { Machine } from 'app/shared/machine';
import { Meta, TransferState, Title } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MachineResolver implements Resolve<any> {

    constructor(
        private machineService: GeneralService,
        private meta: Meta,
        private title: Title,
        private transferState: TransferState
    ) { }

    MACHINE_KEY = makeStateKey<Machine>('Machine');

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Machine> {
        const lang = route.paramMap.get('lang'); // Get language from URL
        const machineId = route.paramMap.get('id');


        if (this.transferState.hasKey(this.MACHINE_KEY)) {
            const machineData = this.transferState.get<Machine>(this.MACHINE_KEY, null);
            if (machineId === machineData._id) {
                this.transferState.remove(this.MACHINE_KEY);
                this.updateMetaTags(machineData, lang);
                return of(machineData);
            }

        }

        return this.machineService.getItem('machine/getMachineByName', machineId).pipe(
            switchMap(machineData => {
                this.transferState.set(this.MACHINE_KEY, machineData);
                this.updateMetaTags(machineData, lang);
                return of(machineData);
            })
        );
    }

    private updateMetaTags(machineData: Machine, lang: string): void {
        if (machineData) {
            try {
                this.title.setTitle(machineData.name[lang]);
                this.meta.updateTag({ property: 'og:title', content: machineData.metaTag[lang] });
                this.meta.updateTag({ property: 'og:description', content: machineData.metaDescription[lang] });
                this.meta.updateTag({ property: 'og:image', content: machineData.images[0].url });
            } catch (error) {
                console.error(error);
            }
        }

    }
}
