<div class="relative w-full top-0 h-[4.6rem] bg-gray-900"></div>
<div [ngClass]="activeLang === 'AR' ? 'arabic' : 'english'">
  <div *ngIf="item | async as item">
    <div class="flex items-center justify-center mt-10">
      <h1 class="text-center arabic inline-block" [dir]="activeLang === 'AR' ? 'rtl' : 'ltr'">
        {{ item.title[activeLang] }}
      </h1>
    </div>
    <div class="w-full my-10">
      <hr class="px-3 lg:px-24 xl:px-32" />
    </div>
    <div class="lg:px-14 xl:px-32">
      <div class="bg-[#dedede17] shadow-md rounded-lg grid lg:flex xl:flex justify-center p-5">
        <div class="w-full lg:w-2/6 xl:w-2/6 lg:pr-5 xl:pr-5">
          <img *ngIf="item.image.url !== 'none'" class="rounded-lg" [src]="item.image.url" loading="lazy" width="100%" alt="zirve pachaging" />
        </div>
        <div class="w-full lg:w-4/6 grid xl:w-4/6 pl-5" [dir]="activeLang === 'AR' ? 'rtl' : 'ltr'">
          <p class="text-lg pb-7 mt-2 text-[#E60027]">
            {{ item.date | date : "M/d/yyy" }}
          </p>
          <span [innerHTML]="item.description[activeLang] | safeHtml"></span>
          <a
            *ngIf="item.youtubeLink != ''"
            type="button"
            [href]="item.youtubeLink"
            target="_blank"
            class="w-full rounded-md text-center bg-[#E60027] px-2.5 h-12 py-1.5 text-lg text-white shadow-sm hover:bg-[#D40024]">
            {{ "watchVideoOnYoutube" | transloco }}
          </a>
        </div>
      </div>
    </div>
    <div *ngIf="item.youtubeLink != ''">
      <iframe
        class="w-full px-2 lg:w-2/3 xl:w-2/3 center mt-10"
        width="100%"
        height="400"
        [src]="item.youtubeLink"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; 
        encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen>
      </iframe>
    </div>
    <div class="w-full lg:w-2/3 xl:w-1/2 mx-auto mt-10">
      <swiper-container
        class="mySwiper"
        pagination="true"
        pagination-clickable="true"
        navigation="true"
        space-between="30"
        centered-slides="true"
        autoplay-delay="2500"
        autoplay-disable-on-interaction="false">
        <swiper-slide *ngFor="let img of item.newsImages">
          <img [src]="img.url" />
        </swiper-slide>
      </swiper-container>
      <!-- <swiper-container
        slides-per-view="1"
        pagination="true"
        autoplay
        rewind="true"
        speed="1500"
        autoplay-delay="10000"
      >
        <swiper-slide *ngFor="let img of item.newsImages">
          <img
            [src]="img.url"
            loading="lazy"
            class="object-contain"
            alt="news"
          />
        </swiper-slide>
      </swiper-container> -->
      <!-- <div
        class="w-full grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6"
      >
        <div class="px-2" *ngFor="let img of item.newsImages">
          <img class="w-full" [src]="img.url" loading="lazy" alt="" />
        </div>
      </div> -->
    </div>
  </div>
</div>

<div class="my-60"></div>
