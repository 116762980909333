import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Renderer2 } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { GeneralService } from 'app/services/general.service';
import { Meta } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-complaints-suggestions',
  templateUrl: './complaints-suggestions.component.html',
  styleUrls: ['./complaints-suggestions.component.scss']
})
export class ComplaintsSuggestionsComponent implements OnInit {
  contactForm: FormGroup;
  scriptElement;
  successMSG: string;
  activeLang: string = 'EN';
  firstRun: boolean;
  private langChangesSubscription: Subscription;
  formErrors = {
    name: '',
    lastName: '',
    email: '',
    country: '',
    message: ''
  };
  validationMessages = {
    name: {
      required: 'Name is required.',
      minlength: 'Name must be at least 2 characters long.'
    },
    lastName: {
      required: 'Last Name is required.',
      minlength: 'Last Name must be at least 2 characters long.'
    },
    email: {
      required: 'Email is required.',
      email: 'Please enter a valid email address.'
    },
    country: {
      required: 'Country is required.'
    },
    message: {
      required: 'Message is required.',
      minlength: 'Message must be at least 10 characters long.'
    }
  };
  constructor(private fb: FormBuilder, private renderer: Renderer2, private _translocoService: TranslocoService, private generalServices: GeneralService, private meta: Meta,) { }

  ngOnInit() {
    if (typeof document === 'undefined') {
      return; // Not running in the browser, do nothing
    }
    this.getActiveLang();
    this.createForm();

  }



  createForm() {
    this.contactForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(2)]],
      lastName: ['', [Validators.required, Validators.minLength(2)]],
      email: ['', [Validators.required, Validators.email]],
      country: ['', Validators.required],
      message: ['', [Validators.required, Validators.minLength(10)]]
    });
    this.contactForm.valueChanges.subscribe(data => this.onValueChanged(data));
    this.onValueChanged(); // Reset validation messages now
  }



  getActiveLang() {
    this.langChangesSubscription = this._translocoService.langChanges$.subscribe((activeLang) => {
      this.activeLang = activeLang;
    });
  }

  onValueChanged(data?: any) {
    if (!this.contactForm) {
      return;
    }
    const form = this.contactForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  onSubmit() {
    if (this.contactForm.valid) {
      const formData = {
        ...this.contactForm.value
      }
      this.generalServices.addItem('complaintsSuggestions', formData).subscribe((res) => {
        if (res.status === 'success') {
          switch (this.activeLang) {
            case 'AR':
              this.successMSG = 'لقد تلقينا رسالتك وسوف نقوم بالرد في أقرب وقت ممكن';
              break;
            case 'EN':
              this.successMSG = 'We received your message and will respond as soon as possible';
              break;
            case 'FR':
              this.successMSG = 'Nous avons reçu votre message et vous répondrons dans les plus brefs délais';
              break;
            case 'RU':
              this.successMSG = 'Мы получили ваше сообщение и ответим как можно скорее';
              break;
            case 'TR':
              this.successMSG = 'Mesajınızı aldık ve en kısa sürede yanıt vereceğiz';
              break;

            default:
              break;
          }

        }
      })
    }
  }

}