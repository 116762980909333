<div class="fixed bottom-6 left-3 z-50">
  <a href="https://wa.me/+905301485665" target="_blank" matTooltip="Whatsapp">
    <img class="mx-auto" src="assets\contact\whatsapp.png" loading="lazy" alt="X Logo" />
  </a>
</div>
<div class="fixed bottom-6 right-3 z-50">
  <a *ngIf="!isOpen" class="cursor-pointer" matTooltip="Open Chat Box" (click)="openChatBoxFun()">
    <img class="w-12" src="assets\home\chat.png" loading="lazy" alt="Chat Icon" />
    <span *ngIf="newMessage" class="relative bottom-12 right-[0.3rem]"><i class="fas fa-circle text-red-700"></i></span>
  </a>
  <div class="w-[20rem] h-[21rem] bg-white rounded-lg" *ngIf="isOpen">
    <div class="h-16 flex items-center p-3 rounded-tr-lg rounded-tl-lg bg-zinc-300">
      <img src="assets/home/logo30w.png" alt="Logo" class="w-14" />
      <div class="ml-4">
        <p class="text-lg font-medium">Zirve Packaging</p>
        <p class="text-xs text-slate-600">
          {{ "respondSwiftly" | transloco }}
        </p>
      </div>
      <a class="cursor-pointer ml-auto" matTooltip="Close Chat Box" (click)="openChatBoxFun()">
        <i class="fas fa-times text-lg mainColor"></i>
      </a>
    </div>
    <div class="h-[13.5rem] overflow-y-auto" #scrollContainer>
      <div class="p-2" *ngFor="let item of messages">
        <p
          class="w-5/6 px-4 py-4 rounded-xl messageBox"
          [ngClass]="{
            'float-right userColor': item.isMe,
            'float-left zirveColor': !item.isMe
          }">
          {{ item.message }}
          <br />
          <span class="text-[10px]">
            {{ item.messageTime | date : "dd/MM/yyyy - HH:mm" }}
          </span>
        </p>
      </div>
    </div>
    <div class="h-14 flex items-center p-2">
      <div class="w-10/12 pr-2">
        <input matInput [(ngModel)]="typedMessage" class="w-full h-10 rounded-lg border border-neutral-500 pl-2" placeholder="Your Message Here" type="text" autocomplete="off" />
      </div>

      <div class="w-2/12">
        <button
          type="button"
          (click)="sendMessage()"
          class="rounded-full center w-full px-2.5 py-1.5 text-lg text-white shadow-sm bg-[#128c7e] hover:bg-[#24665e] disabled:bg-[#7daca6]">
          <i class="fas fa-paper-plane"></i>
        </button>
      </div>
    </div>
  </div>
</div>
