<div class="hidden lg:block">
  <nav [ngClass]="{ 'bg-gray-900': isScrolled, 'bg-[#00000067]': !isScrolled }" class="fixed top-0 w-full py-2 px-16 z-50">
    <div class="flex items-center justify-between">
      <div class="cursor-pointer" [routerLink]="[activeLang, 'home']">
        <img src="assets/home/logo30w.png" alt="Logo" class="xl:w-52 lg:w-16 lg:mr-5" />
      </div>
      <div class="flex items-center text-white" [dir]="activeLang === 'AR' ? 'rtl' : 'ltr'">
        <a [routerLink]="[activeLang, 'home']" routerLinkActive="active-link" class="mr-8 linksFontSiz linkWithUnderline">
          {{ "home" | transloco }}
        </a>
        <a [routerLink]="[activeLang, 'equipment_categories']" routerLinkActive="active-link" class="mr-8 linksFontSiz linkWithUnderline">
          {{ "equipmentCategories" | transloco }}
        </a>
        <a href="https://zirveextrusion.net/en/home" target="_blank" routerLinkActive="active-link" class="mr-8 linksFontSiz linkWithUnderline">
          {{ "productionLines" | transloco }}
        </a>
        <a [routerLink]="[activeLang, 'news']" routerLinkActive="active-link" class="mr-8 linksFontSiz linkWithUnderline">
          {{ "news" | transloco }}
        </a>
        <div class="dropdown aboutUsElement mr-8" (click)="openAboutUsMenu()">
          <button class="dropbtn flex text-nowrap">
            {{ "aboutUsN" | transloco }}
            <i class="fa fa-caret-down mx-1"></i>
          </button>
          <div
            [@openClose]="showClass ? 'open' : 'closed'"
            class="dropdown-content"
            [ngClass]="{
              dropdownClassOpen: !showClass,
              dropdownClassClose: showClass
            }">
            <a [routerLink]="[activeLang, 'about_company']" class="block px-4 py-2 rounded-t-md text-gray-800 hover:bg-[#e60027] hover:text-white">
              {{ "aboutCompany" | transloco }}</a
            >
            <a [routerLink]="[activeLang, 'general_manager']" class="block px-4 py-2 rounded-t-md text-gray-800 hover:bg-[#e60027] hover:text-white">
              {{ "generalManager" | transloco }}</a
            >
          </div>
        </div>
        <a [routerLink]="[activeLang, 'blogs']" routerLinkActive="active-link" class="mr-8 linksFontSiz linkWithUnderline">
          {{ "blogs" | transloco }}
        </a>
        <a [routerLink]="[activeLang, 'contact']" routerLinkActive="active-link" class="mr-8 linksFontSiz linkWithUnderline">
          {{ "contact" | transloco }}
        </a>
      </div>
      <div class="flex items-center">
        <button mat-icon-button [matMenuTriggerFor]="languages">
          <ng-container *ngTemplateOutlet="flagImage; context: { $implicit: activeLang }"></ng-container>
        </button>
        <mat-menu [xPosition]="'before'" #languages="matMenu">
          <ng-container *ngFor="let lang of availableLangs; trackBy: trackByFn">
            <button class="langsClass" mat-menu-item (click)="setActiveLang(lang.id)">
              <span class="flex items-center">
                <ng-container *ngTemplateOutlet="flagImage; context: { $implicit: lang.id }"></ng-container>
                <span
                  class="ml-3"
                  [ngClass]="{
                    arabic: activeLang === 'AR',
                    poppins: activeLang !== 'AR'
                  }"
                  >{{ lang.label }}</span
                >
              </span>
            </button>
          </ng-container>
        </mat-menu>
        <div class="profileElement relative ml-4" (click)="toggleDropdown()">
          <button class="flex items-center focus:outline-none">
            <img src="assets\home\profile.jpg" alt="Profile" class="w-6 rounded-full" />
          </button>
          <div
            [@openClose]="isOpen ? 'open' : 'closed'"
            [ngClass]="{
              dropdownClassOpen: !isOpen,
              dropdownClassClose: isOpen
            }"
            class="absolute -right-5 mt-2 w-32 bg-white rounded-md shadow-lg z-10">
            <div *ngIf="user">
              <a (click)="_authService.signOut()" class="block px-4 py-2 cursor-pointer rounded-b-md text-gray-800 hover:bg-[#e60027] hover:text-white">
                {{ "logout" | transloco }}</a
              >
            </div>
            <div *ngIf="!user">
              <a [routerLink]="[activeLang, 'sign_in']" class="block px-4 py-2 rounded-t-md text-gray-800 hover:bg-[#e60027] hover:text-white"> {{ "login" | transloco }}</a>
              <a [routerLink]="[activeLang, 'sign_up']" class="block px-4 py-2 rounded-b-md text-gray-800 hover:bg-[#e60027] hover:text-white"> {{ "signUp" | transloco }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</div>

<div class="block lg:hidden z-50">
  <nav [ngClass]="{ 'bg-[#000000e1]': isScrolled, 'bg-[#00000077]': !isScrolled }" class="fixed top-0 w-full p-2 z-50">
    <div class="flex items-center justify-between z-50">
      <div class="flex items-center">
        <a class="cursor-pointer mr-3 ml-2" (click)="toggleSidenav()"> <i class="fas fa-bars text-white text-xl"></i> <br /> </a>
        <a class="cursor-pointer" [routerLink]="[activeLang, 'home']">
          <img src="assets/home/logo30w.png" alt="Logo" class="w-28 lg:mr-5" />
        </a>
      </div>
      <div class="flex items-center">
        <button class="z-50" mat-icon-button [matMenuTriggerFor]="languages">
          <ng-container *ngTemplateOutlet="flagImage; context: { $implicit: activeLang }"></ng-container>
        </button>
        <mat-menu [xPosition]="'before'" #languages="matMenu">
          <ng-container *ngFor="let lang of availableLangs; trackBy: trackByFn">
            <button class="langsClass" mat-menu-item (click)="setActiveLang(lang.id)">
              <span class="flex items-center">
                <ng-container *ngTemplateOutlet="flagImage; context: { $implicit: lang.id }"></ng-container>
                <span
                  class="ml-3"
                  [ngClass]="{
                    arabic: activeLang === 'AR',
                    poppins: activeLang !== 'AR'
                  }"
                  >{{ lang.label }}</span
                >
              </span>
            </button>
          </ng-container>
        </mat-menu>
        <div class="profileElement relative ml-4" (click)="toggleDropdown()">
          <button class="flex items-center focus:outline-none">
            <img src="assets\home\profile.jpg" alt="Profile" class="w-6 rounded-full" />
          </button>
          <div
            [@openClose]="isOpen ? 'open' : 'closed'"
            [ngClass]="{
              dropdownClassOpen: !isOpen,
              dropdownClassClose: isOpen
            }"
            class="absolute -right-5 mt-2 w-32 bg-white rounded-md shadow-lg z-10">
            <div *ngIf="user">
              <a (click)="_authService.signOut()" class="block px-4 py-2 cursor-pointer rounded-b-md text-gray-800 hover:bg-[#e60027] hover:text-white">
                {{ "logout" | transloco }}</a
              >
            </div>
            <div *ngIf="!user">
              <a [routerLink]="[activeLang, 'sign_in']" class="block px-4 py-2 rounded-t-md text-gray-800 hover:bg-[#e60027] hover:text-white"> {{ "login" | transloco }}</a>
              <a [routerLink]="[activeLang, 'sign_up']" class="block px-4 py-2 rounded-b-md text-gray-800 hover:bg-[#e60027] hover:text-white"> {{ "signUp" | transloco }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</div>
<ng-template let-lang #flagImage>
  <span class="relative w-4 shadow rounded-sm overflow-hidden">
    <img class="w-full" [src]="'assets/images/flags/' + flagCodes[lang] + '.svg'" [alt]="'Flag image for ' + lang" />
  </span>
</ng-template>
