<div class="relative w-full top-0 h-[4.6rem] bg-gray-900"></div>
<div [ngClass]="activeLang === 'AR' ? 'arabic' : 'english'">
  <div class="w-full lg:w-5/12 center text-center mt-8">
    <p
      class="inline-block text-xl lg:text-2xl mainColor font-black border border-black px-5 py-1 rounded-full"
    >
      {{ "aboutCompany" | transloco }}
    </p>
    <p class="text-[#393939] text-1xl pt-5">
      {{ "aboutZirveD" | transloco }}
    </p>
  </div>
  <div class="px-2 lg:px-24 my-20">
    <mat-accordion [dir]="activeLang === 'AR' ? 'rtl' : 'ltr'">
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <mat-panel-title class="font-extrabold arabic">
            {{ "QualityAccuracyD" | transloco | truncate : 4 }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="arabic">
          {{ "QualityAccuracyD" | transloco }}
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="font-extrabold arabic">
            {{ "LessformaintenanceD" | transloco | truncate : 4 }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="arabic">
          {{ "LessformaintenanceD" | transloco }}
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="font-extrabold arabic">
            {{ "ZiRVEWARRANTYD" | transloco | truncate : 4 }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="arabic">
          {{ "ZiRVEWARRANTYD" | transloco }}
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="font-extrabold arabic">
            {{ "ClientOrientedApproachD" | transloco | truncate : 4 }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="arabic">
          {{ "ClientOrientedApproachD" | transloco }}
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="font-extrabold arabic">
            {{ "SpecialtyfeaturesD" | transloco | truncate : 4 }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="arabic">
          {{ "SpecialtyfeaturesD" | transloco }}
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="font-extrabold arabic">
            {{ "WideOutreachD" | transloco | truncate : 4 }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="arabic">
          {{ "WideOutreachD" | transloco }}
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="font-extrabold arabic">
            {{ "WhatdoesprovideD" | transloco | truncate : 4 }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="arabic">
          {{ "WhatdoesprovideD" | transloco }}
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="font-extrabold arabic">
            {{ "WhyZiRVED" | transloco | truncate : 4 }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="arabic">
          {{ "WhyZiRVED" | transloco }}
        </p>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="font-extrabold arabic">
            {{ "AfterServicesD" | transloco | truncate : 4 }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="arabic">
          {{ "AfterServicesD" | transloco }}
        </p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="px-2 lg:px-14 grid lg:flex my-10">
    <div class="w-full sm:w-2/3 lg:w-1/3 lg:pr-5 center">
      <img
        class="rounded-lg"
        src="assets\aboutUS\aymen.webp"
        width="100%"
        loading="lazy"
        alt=""
      />
    </div>
    <div class="w-full lg:w-2/3" [dir]="activeLang === 'AR' ? 'rtl' : 'ltr'">
      <div class="rounded-lg shadow-sm p-2 bg-white">
        <p class="text-base font-bold">
          {{ "dearCustomers" | transloco }}
        </p>
      </div>
      <div class="rounded-lg shadow-sm p-2">
        <p class="text-base">
          {{ "quality" | transloco }}
        </p>
      </div>
      <div class="rounded-lg shadow-sm p-2 bg-white">
        <p class="text-base">
          {{ "ourRespons" | transloco }}
        </p>
      </div>
      <div class="rounded-lg shadow-sm p-2">
        <p class="text-base">
          {{ "weApproud" | transloco }}
        </p>
      </div>
      <div class="rounded-lg shadow-sm p-2 bg-white">
        <p class="text-base">
          {{ "thankYou" | transloco }}
        </p>
      </div>
      <div class="rounded-lg shadow-sm p-2">
        <p class="text-base">
          {{ "respect" | transloco }}
        </p>
      </div>
      <div class="p-2 bg-white">
        <p class="font-extrabold text-xl mainColor">
          {{ "aymenH" | transloco }}
        </p>
        <p class="font-medium text-lg mainColor">
          {{ "qualityControl" | transloco }}
        </p>
      </div>
    </div>
  </div>
</div>
<div class="w-full my-5">
  <hr />
</div>
<div id="map" class="w-full xl:w-2/3 h-[500px] center my-14 z-0"></div>
