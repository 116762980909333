import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class HreflangService {

    constructor(private meta: Meta) { }

    updateHreflangTags(activeLang: string, route: string) {
        const languages = ['EN', 'TR', 'FR', 'RU', 'AR'];
        this.meta.addTag({ rel: 'canonical', href: `https://zirvecompany.net/${activeLang}/${route}` });
        languages.forEach(lang => {
            if (lang !== activeLang) {
                this.meta.addTag({ name: 'hreflang', content: lang, href: `https://zirvecompany.net/${lang}/${route}` });
            }
        });
    }
}
