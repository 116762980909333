import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, PLATFORM_ID, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'app/services/general.service';
import { environment } from 'environments/environment';
import { io } from 'socket.io-client';
import { Socket } from "socket.io-client";
import { TranslocoService } from '@ngneat/transloco';
import { UserService } from 'app/user/user.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements AfterViewInit, OnDestroy {
  isOpen = false;
  isHomeOpen = false;
  isProductOpen = false;
  userInfo: any = null;
  socket: Socket;
  messages: any[] = [];
  activeLang: string = 'EN';
  typedMessage = '';
  storedChatId: string;
  newMessage: boolean;
  private timeoutId: any;
  readyMessages: any[] = [
    {
      homaPage: {
        "AR": "مرحبا, كيف يمكننا مساعدتك ؟",
        "EN": "Hi, How can we assist you ?",
        "TR": "Merhaba, size nasıl yardımcı olabiliriz?",
        "FR": "Bonjour, comment pouvons-nous vous aider",
        "RU": "Привет. Чем мы можем вам помочь?",
      }
    },
    {
      productsPage: {
        "AR": "هل انت مهتم بهذه الآلة ؟",
        "EN": "Are you interested in this machine ?",
        "TR": "Bu makineyle ilgileniyor musunuz?",
        "FR": "Êtes-vous intéressé par cette machine?",
        "RU": "Вас интересует эта машина?",
      }
    }


  ];

  @ViewChild('scrollContainer') private scrollContainer: ElementRef;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private generalService: GeneralService,
    private router: Router,
    private _userService: UserService,
    private _translocoService: TranslocoService,

  ) { }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this._userService.user$.subscribe((res) => {
        this.userInfo = res;
        this.checkUserChatId();
      })
      this.getActiveLang();
      setTimeout(() => {
        this.checkUserChatId();
      }, 4000);
    }
  }

  checkUserChatId() {
    if (this.userInfo) {
      if (this.userInfo.chatId && this.userInfo.chatId !== "000000000000000000000000") {
        this.storedChatId = this.userInfo.chatId;
        // this.connectToSocket();
        this.getMessages();
      } else {
        this.setupTimeout();
        // this.connectNewUser();
      }
    } else {
      let localStoredChatId = localStorage.getItem('chatS');
      if (localStoredChatId) {
        this.storedChatId = localStorage.getItem('chatS');
        // this.connectToSocket();
        this.getMessages();
      } else {
        // this.connectNewUser();
        this.setupTimeout();
      }
    }
  }

  getActiveLang() {
    this._translocoService.langChanges$.subscribe((activeLang) => {
      this.activeLang = activeLang;
    });
  }

  setupTimeout(): void {
    const currentUrl = this.router.url;
    const parts = currentUrl.split('/');
    const product = parts[2];
    if (product === 'home' && !this.isHomeOpen) {
      this.startTimeout(this.readyMessages[0].homaPage[this.activeLang], () => this.isHomeOpen = true);
    } else if (product === 'product' && !this.isProductOpen) {
      this.startTimeout(this.readyMessages[1].productsPage[this.activeLang], () => this.isProductOpen = true);
    } else {
      clearTimeout(this.timeoutId);
    }

  }

  startTimeout(message: string, callback: () => void): void {
    if (this.messages.length === 0) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.messages = [{ isMe: false, message, messageTime: new Date().toISOString() }];
        this.isOpen = true;
        callback();
      }, 120000);
    }

  }

  connectToSocket(): void {
    if (this.socket) {
      this.socket.off(this.storedChatId); // Remove the listener
      this.socket.disconnect(); // Disconnect the socket
    }
    this.socket = io(environment.baseUrl2, { query: { chatId: this.storedChatId, lang: this.activeLang } });
    this.socket.on(this.storedChatId, (data) => {
      this.messages.push(data);
      this.newMessage = true;
      if (this.isOpen) {
        setTimeout(() => this.scrollToBottom(), 100);
      }
    });
  }

  scrollToBottom(): void {
    try {
      if (this.scrollContainer) {
        this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;

      }
    } catch (err) {
      console.error('Failed to scroll to bottom:', err);
    }
  }

  sendMessage(): void {

    if (this.typedMessage.length < 2) {
      return;
    }
    if (!this.storedChatId) {
      this.connectNewUser();
      return;
    }

    const data = { isMe: true, message: this.typedMessage, isUserMessage: true, isZirveMessage: false, messageTime: new Date().toISOString() };
    this.generalService.editItem('chat', this.storedChatId, data).subscribe(() => {
      this.messages.push({ isMe: true, message: this.typedMessage, messageTime: data.messageTime });
      setTimeout(() => this.scrollToBottom(), 100);
      this.newMessage = false;
      this.typedMessage = '';
    }, err => console.error('Failed to send message:', err));
  }

  connectNewUser(): void {
    if (this.storedChatId) {
      this.connectToSocket();
      return;
    }
    let message;
    if (this.messages[0]) {
      message = this.messages[0];
    } else {
      message = [];
    }
    this.generalService.addItem('chat', { messages: message, isOnline: true, socketId: 'notYet', isZirveMessage: true, isUserMessage: false, usedLang: this.activeLang }).subscribe((res) => {
      this.storedChatId = res._id;
      if (!this.userInfo) {
        localStorage.setItem('chatS', this.storedChatId);
      } else {
        this.generalService.editItem('usersRouter/addChatId', this.userInfo._id, { chatId: res._id }).subscribe();
      }
      this.connectToSocket();

    }, err => console.error('Failed to add new user:', err));
  }

  getMessages(): void {
    if (this.storedChatId) {
      this.generalService.getItem('chat', this.storedChatId).subscribe((chatRes) => {
        if (chatRes && chatRes.messages) {
          this.messages = chatRes.messages;
          if (chatRes.isZirveMessage) {
            // this.isOpen = true;
            setTimeout(() => this.scrollToBottom(), 500);
            this.newMessage = true;
          }
        }
      }, err => console.error('Failed to get messages:', err));
    }

  }



  openChatBoxFun() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      setTimeout(() => {
        this.scrollToBottom();
      }, 500);
    }


  }

  ngOnDestroy(): void {
    if (this.socket) {
      this.socket.off(this.storedChatId); // Remove the listener
      this.socket.disconnect(); // Disconnect the socket
    }
  }

}