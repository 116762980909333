import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { MetaTagService } from 'app/services/meta-tag.service';
import { switchMap } from 'rxjs/operators';
import { Meta, Title, TransferState, makeStateKey } from '@angular/platform-browser';
import { MetaTag } from 'app/shared/mate_tag';

@Injectable({
    providedIn: 'root'
})
export class MetaTagResolver implements Resolve<MetaTag> {
    private metaTagCache: Map<string, MetaTag> = new Map(); // In-memory Cache

    constructor(
        private metaTagService: MetaTagService,
        private title: Title,
        private transferState: TransferState,
        private meta: Meta,
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MetaTag> {
        const pageName = route.data['pageName'];
        const lang = route.paramMap.get('lang');
        const META_KEY = makeStateKey<MetaTag>('metaTag_' + pageName + '_' + lang);

        // 1. Check in-memory cache first
        if (this.metaTagCache.has(META_KEY)) {
            const metaTagRes = this.metaTagCache.get(META_KEY);
            this.updateMetaTags(metaTagRes, lang);
            return of(metaTagRes);
        }

        // 2. Check TransferState
        if (this.transferState.hasKey(META_KEY)) {
            const metaTagRes = this.transferState.get(META_KEY, null);
            this.transferState.remove(META_KEY);
            this.metaTagCache.set(META_KEY, metaTagRes); // Store in the cache
            this.updateMetaTags(metaTagRes, lang);
            return of(metaTagRes);
        }

        // 3. Fetch from service if not found in either cache
        return this.metaTagService.getMetaTags().pipe(
            switchMap(metaTags => {
                const metaTagRes = metaTags.find(metaTag => metaTag.pageName === pageName);
                this.transferState.set(META_KEY, metaTagRes);
                this.metaTagCache.set(META_KEY, metaTagRes); // Store in the cache
                this.updateMetaTags(metaTagRes, lang);
                return of(metaTagRes);
            })
        );
    }

    private updateMetaTags(metaTagRes: MetaTag, lang: string) {
        if (metaTagRes) {
            this.title.setTitle(metaTagRes['pageTitle']?.[lang]);
            this.meta.updateTag({ property: 'og:title', content: metaTagRes['metaTitle']?.[lang] });
            this.meta.updateTag({ property: 'og:description', content: metaTagRes['metaDescription']?.[lang] });
            this.meta.updateTag({ property: 'og:image', content: 'https://msr.zirvecompany.net/images/logo30.png' });
            if (metaTagRes.noIndex) {
                this.meta.addTag({ name: 'robots', content: 'noindex' });
            }
            if (metaTagRes.noFollow) {
                this.meta.addTag({ name: 'robots', content: 'nofollow' });
            }
        }

    }
}
