import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { HreflangService } from 'app/services/hreflang.service';
import { Equipment } from 'app/shared/equipment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-equipment_categories',
  templateUrl: './equipment_categories.component.html',
  styleUrls: ['./equipment_categories.component.scss']
})
export class EquipmentCategoriesComponent implements OnInit, OnDestroy {
  equipment: Equipment[] = [];
  activeLang: string = 'EN';
  firstRun: boolean;
  private langChangesSubscription: Subscription;

  constructor(
    public router: ActivatedRoute,
    private _translocoService: TranslocoService, private meta: Meta, private hreflangService: HreflangService,) { }


  ngOnInit(): void {

    if (typeof document === 'undefined') {
      return; // Not running in the browser, do nothing
    }
    this.getEquipmentsData();
    this.getActiveLang();
  }

  getActiveLang() {
    this.langChangesSubscription = this._translocoService.langChanges$.subscribe((activeLang) => {
      if (this.activeLang !== activeLang || !this.firstRun) {
        this.deleteSchemaMeta();
        setTimeout(() => {
          this.hreflangService.updateHreflangTags(activeLang, 'equipment_categories');
        }, 1000);
        this.firstRun = true;
      }
      this.activeLang = activeLang;
    });
  }

  getEquipmentsData() {
    this.router.data.subscribe((data: any) => {
      this.equipment = data.equipments;
    });
  }

  ngOnDestroy() {
    if (this.langChangesSubscription) {
      this.langChangesSubscription.unsubscribe();
    }
    // Delete Robots
    const robotTags = this.meta.getTags('name="robots"');
    if (robotTags.length > 0) {
      robotTags.forEach(tag => {
        try {
          this.meta.removeTagElement(tag);
        } catch (error) {
          console.error('Error removing robot tag:', error);
        }
      });
    }

    this.deleteSchemaMeta();
  }


  deleteSchemaMeta() {
    // Delete Hreflang
    const hreflangTags = this.meta.getTags('name="hreflang"');
    if (hreflangTags.length > 0) {
      hreflangTags.forEach(tag => {
        try {
          this.meta.removeTagElement(tag);
        } catch (error) {
          console.error('Error removing hreflang tag:', error);
        }
      });
    }

    // Delete Canonical
    try {
      this.meta.removeTag('rel=canonical');
    } catch (error) {
      console.error('Error removing canonical tag:', error);
    }
  }

}
