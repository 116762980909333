<div class="relative w-full top-0 h-[4.6rem] bg-gray-900"></div>
<div [ngClass]="activeLang === 'AR' ? 'arabic' : 'english'">
  <div class="flex items-center justify-center my-10">
    <p
      class="text-center inline-block text-3xl border border-black px-5 py-1 rounded-full"
    >
      {{ type | uppercase }}
    </p>
  </div>
  <div
    class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-5 lg:gap-16 px-2 lg:px-44 mt-10"
  >
    <div
      class="drop-shadow-xl rounded-t-xl"
      *ngFor="let item of items$ | async"
    >
      <a
        class="cursor-pointer"
        [matTooltip]="item.title[activeLang]"
        matTooltipPosition="above"
        [routerLink]="['/', activeLang, type360, item._id]"
      >
        <img
          *ngIf="item.documents[0]?.url !== 'none'"
          class="center object-cover grayscale-0 hover:grayscale transition-all"
          width="70%"
          [src]="item.documents[0]?.url"
          loading="lazy"
          [alt]="item.title[activeLang]"
        />
      </a>
      <a
        *ngIf="type360 === 'bag'"
        (click)="openViewerDialog(item.documents, item.folderName)"
        class="cursor-pointer mainColor"
        [matTooltip]="'View As 360'"
      >
        <img class="w-10 mx-auto" src="assets\home\degrees.svg"
      /></a>
    </div>
  </div>
</div>

<div class="my-60"></div>
